<template>
  <div class="index">
    <div class="content" style="overflow:hidden;">
      <van-tabs v-model="active" @change="tabChange" style="margin-top: 10px;" color="#1890ff" title-active-color="#1890ff">
        <van-tab name="1" title="客户统计">
          <div class="basecard">
            <!-- 个人客户统计 -->
            <div class="header">
              <p class="header__title">个人客户统计</p>
              <div class="header__right">
                <div class="switch">
                  <a-select style="min-width: 80px" size="small" :value="checkType" @change="changeCheckType">
                    <a-select-option :key="1">今天</a-select-option>
                    <a-select-option :key="2">昨天</a-select-option>
                    <a-select-option :key="3">本月</a-select-option>
                    <a-select-option :key="4" @click="showSelectDay = true; setTimeType = 1">{{ setTimeCheck ? dateStr.customer.startDay + '至' + dateStr.customer.endDay : '自定义' }}</a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="databox">
              <div class="list">
                 <div class="list__item">
                    <p class="list__item__count">{{ statistics.total }}</p>
                    <p class="list__item__desc">总人数</p>
                  </div>
                <div class="list__item">
                  <p class="list__item__count">{{ statistics.add }}</p>
                  <p class="list__item__desc">新增</p>
                </div>
                <div class="list__item">
                  <p class="list__item__count">{{ statistics.loss }}</p>
                  <p class="list__item__desc">流失</p>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="echartBox">
              <div id="lineEchart" :style="{width: '100%', height: '300px'}"></div>
            </div>
            
            <!-- 部门客户统计 -->
            <template v-if="userPermission < 2">
              <div class="header">
                <p class="header__title">部门客户统计</p>
                <div class="header__right">
                  <div class="switch">
                    <a-select style="min-width: 80px" size="small" :value="checkTypeDepartment" @change="changeCheckTypeDepartment">
                      <!-- <a-select-option :key="1">今天</a-select-option> -->
                      <a-select-option :key="2">昨天</a-select-option>
                      <a-select-option :key="3">本月</a-select-option>
                      <a-select-option :key="4" @click="showSelectDay = true; setTimeType = 2">{{ setTimeCheckDepartment ? dateStr.customerDepartment.startDay + '至' + dateStr.customerDepartment.endDay : '自定义' }}</a-select-option>
                    </a-select>
                  </div>
                </div>
              </div>
              <div class="databox">
                <div class="list">
                  <div class="list__item">
                      <p class="list__item__count">{{ departmentStatistics.total }}</p>
                      <p class="list__item__desc">总人数</p>
                    </div>
                  <div class="list__item">
                    <p class="list__item__count">{{ departmentStatistics.add }}</p>
                    <p class="list__item__desc">新增</p>
                  </div>
                  <div class="list__item">
                    <p class="list__item__count">{{ departmentStatistics.loss }}</p>
                    <p class="list__item__desc">流失</p>
                  </div>
                </div>
                <div class="list">
                  <div class="list__item">
                      <p class="list__item__count">{{ departmentStatistics.totalDistinct }}</p>
                      <p class="list__item__desc">总人数(去重)</p>
                    </div>
                  <div class="list__item">
                    <p class="list__item__count">{{ departmentStatistics.addDistinct }}</p>
                    <p class="list__item__desc">新增(去重)</p>
                  </div>
                  <div class="list__item">
                    <p class="list__item__count">{{ departmentStatistics.lossDistinct }}</p>
                    <p class="list__item__desc">流失(去重)</p>
                  </div>
                </div>
              </div>
              <div class="echartBox">
                <div id="departmentStatisticsEchart" :style="{width: '100%', height: '300px'}"></div>
              </div>
              <div style="margin: 0 16px">
                <!-- <van-button @click="$router.push('/employeeContactStatistics')" type="info" block>查看部门员工客户数</van-button> -->
                <van-list
                  v-model="loading"
                  :finished="finished"
                  :immediate-check="false"
                  @load="onLoad"
                >
                  <van-cell-group inset v-for="(item, index) in list" :key="index" border class="list-item" @click="checkDetail(item.id)">
                    <van-cell style="align-items: center;">
                      <template #title>
                        <span class="custom-title list-item-title">
                          <van-image
                            width="44"
                            height="44"
                            fit="cover"
                            round
                            :src="item.avatar"
                          />
                          <div>
                            {{ item.name }}
                          </div>
                        </span>
                      </template>
                      <template>
                        总客户人数：{{ item.total_contact }} 人
                      </template>
                    </van-cell>
                    <van-cell>
                      <template #title>
                        <span class="custom-title list-item-title">
                          新增：
                        </span>
                      </template>
                      <template>
                        {{ item.new_num }} 人
                      </template>
                    </van-cell>
                    <van-cell>
                      <template #title>
                        <span class="custom-title list-item-title">
                          流失：
                        </span>
                      </template>
                      <template>
                        {{ item.loss_num }} 人
                      </template>
                    </van-cell>
                  </van-cell-group>
                  <div v-if="finished && list.length" slot="finished">无更多数据</div>
                </van-list>
                <van-empty
                  v-if="finished && !list.length"
                  class="custom-image"
                  :image="require('../../assets/images/search-no-data.svg')"
                  description="暂无数据"
                />
              </div>
            </template>
          </div>
        </van-tab>

        <van-tab name="2" title="客户群统计">
          <div class="basecard">
            <!-- 个人群统计 -->
            <div class="header">
              <p class="header__title">个人客户群统计</p>
              <div class="header__right">
                <div class="switch">
                  <a-select style="min-width: 80px" size="small" :value="checkTypeQun" @change="changeQunCheckType">
                    <a-select-option :key="1">今天</a-select-option>
                    <a-select-option :key="2">昨天</a-select-option>
                    <a-select-option :key="3">本月</a-select-option>
                    <a-select-option :key="4" @click="showSelectDay = true; setTimeType = 1">{{ setTimeCheckQun ? dateStr.customerQun.startDay + '至' + dateStr.customerQun.endDay : '自定义' }}</a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="databox">
              <div class="list">
                 <div class="list__item">
                    <p class="list__item__count">{{ roomStatistics.total }}</p>
                    <p class="list__item__desc">客户群总数</p>
                  </div>
                <div class="list__item">
                  <p class="list__item__count">{{ roomStatistics.totalContact }}</p>
                  <p class="list__item__desc">群总人数</p>
                </div>
              </div>
              <div class="list" style="padding-top: 0">
                <div class="list__item">
                  <p class="list__item__count">{{ roomStatistics.addContact }}</p>
                  <p class="list__item__desc">新入群客户数</p>
                </div>
                <div class="list__item">
                  <p class="list__item__count">{{ roomStatistics.lossContact }}</p>
                  <p class="list__item__desc">退群客户数</p>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="echartBox">
              <div id="lineEchartQun" :style="{width: '100%', height: '300px'}"></div>
            </div>

            <!-- 部门群统计 -->
            <template v-if="userPermission < 2">
              <div class="header">
                <p class="header__title">部门客户群统计</p>
                <div class="header__right">
                  <div class="switch">
                    <a-select style="min-width: 80px" size="small" :value="checkTypeQunDepartment" @change="changeQunCheckTypeDepartment">
                      <!-- <a-select-option :key="1">今天</a-select-option> -->
                      <a-select-option :key="2">昨天</a-select-option>
                      <a-select-option :key="3">本月</a-select-option>
                      <a-select-option :key="4" @click="showSelectDay = true; setTimeType = 2">{{ setTimeCheckQunDepartment ? dateStr.customerQunDepartment.startDay + '至' + dateStr.customerQunDepartment.endDay : '自定义' }}</a-select-option>
                    </a-select>
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="databox">
                <div class="list">
                  <div class="list__item">
                      <p class="list__item__count">{{ roomDepartmentStatistics.total }}</p>
                      <p class="list__item__desc">客户群总数</p>
                    </div>
                  <div class="list__item">
                    <p class="list__item__count">{{ roomDepartmentStatistics.totalContact }}</p>
                    <p class="list__item__desc">群总人数</p>
                  </div>
                </div>
                <div class="list" style="padding-top: 0">
                  <div class="list__item">
                    <p class="list__item__count">{{ roomDepartmentStatistics.addContact }}</p>
                    <p class="list__item__desc">新入群客户数</p>
                  </div>
                  <div class="list__item">
                    <p class="list__item__count">{{ roomDepartmentStatistics.lossContact }}</p>
                    <p class="list__item__desc">退群客户数</p>
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="echartBox">
                <div id="roomDepartmentEchart" :style="{width: '100%', height: '300px'}"></div>
              </div>
            </template>
          </div>
        </van-tab>
      </van-tabs>

      <!-- 自定义时间 -->
      <van-action-sheet v-model="showSelectDay" :close-on-click-overlay="false">
        <van-datetime-picker
          v-model="currentDate.startDay"
          type="date"
          title="开始日期"
          :formatter="formatter"
          :visible-item-count="4"
        >
          <div slot="confirm"></div>
          <div slot="cancel"></div>
        </van-datetime-picker>
        <van-datetime-picker
          v-model="currentDate.endDay"
          type="date"
          title="结束日期"
          :formatter="formatter"
          :visible-item-count="4"
        >
          <div slot="confirm"></div>
          <div slot="cancel"></div>
        </van-datetime-picker>
        <div class="showSelectDayBtn">
          <van-button @click="clearSetectDay">取消</van-button>
          <van-button type="info" @click="setDate">确认</van-button>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import {contactStatistics,roomStatistics, departmentStatistics, departmentRoomStatistics, employeeContactStatistics } from '../../api/pullNew'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      active: '1',
      // seven: this.dateFormat(new Date() - (86400 * 7 * 1000)),
      // thirty: this.dateFormat(new Date() - (86400 * 30 * 1000)),
      // dayStatistics
      statistics: {},
      dayStatistics: {
        date: [],
        add: [],
        loss: []
      },
      departmentStatistics: {},
      departmentEchartData: {
        date: [],
        add: [],
        loss: []
      },
      checkType: 1,
      checkTypeQun: 1,
      checkTypeDepartment: 2,
      checkTypeQunDepartment: 2,
      showSelectDay: false,
      roomStatistics: {},
      chartCategory: {
        date: [],
        add: [],
        loss: [],
        eoss: []
      },
      roomDepartmentStatistics: {},
      roomDepartmentEchartData: {
        date: [],
        add: [],
        loss: [],
        eoss: []
      },
      currentDate: {
        startDay: new Date(),
        endDay: new Date()
      },
      setTimeType: 1, // 当前触发时间选择的业务类型；
      setTimeCheck: false,
      setTimeCheckQun: false,
      setTimeCheckDepartment: false,
      setTimeCheckQunDepartment: false,
      dateStr: {
        customer: {
          startDay: '',
          endDay: ''
        },
        customerQun: {
          startDay: '',
          endDay: ''
        }, 
        customerDepartment: {
          startDay: '',
          endDay: ''
        },
        customerQunDepartment: {
          startDay: '',
          endDay: ''
        },
      },

      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15,
    }
  },
  watch: {
    // active(e){
    //   const that = this
    //   if(e == '2'){
    //     setTimeout(function () {
    //       that.categoryCategory()
    //     },1)
    //   }
    // }
  },
  computed:{
		...mapGetters([
			'userPermission'
		])
	},
  created () {
    console.log(this.userPermission)
  },
  methods: {
    onLoad (startDay, endDay) {
      this.loading = true
      employeeContactStatistics({
        day: startDay || this.dateFormat(new Date()),
        endDay: endDay || this.dateFormat(new Date()),
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.list.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.list.data || [])
      })
    },
    onClickLeft() {
      this.$router.back()
    },
    clearSetectDay () {
      // switch (this.active) {
      //   case '1':
      //     this.checkType = 1
      //     break
      //   case '2':
      //     this.checkTypeQun = 1
      //     break
      // }
      this.showSelectDay = false
    },
    setDate () {
      let startDay = this.dateFormatStr(this.currentDate.startDay)
      let endDay = this.dateFormatStr(this.currentDate.endDay)
      switch (this.active) {
        case '1':
          if (this.setTimeType == 1) {
            this.customer(startDay, endDay)
            this.dateStr.customer.startDay = startDay
            this.dateStr.customer.endDay = endDay
            this.setTimeCheck = true
          } else if (this.setTimeType == 2 && this.userPermission < 2) {
            this.customerDepartment(startDay, endDay)
            this.dateStr.customerDepartment.startDay = startDay
            this.dateStr.customerDepartment.endDay = endDay
            this.setTimeCheckDepartment = true
          }
          break
        case '2':
          if (this.setTimeType == 1) {
            this.customerBase(startDay, endDay)
            this.dateStr.customerQun.startDay = startDay
            this.dateStr.customerQun.endDay = endDay
            this.setTimeCheckQun = true
          } else if (this.setTimeType == 2 && this.userPermission < 2) {
            this.customerBaseDepartment(startDay, endDay)
            this.dateStr.customerQunDepartment.startDay = startDay
            this.dateStr.customerQunDepartment.endDay = endDay
            this.setTimeCheckQunDepartment = true
          }
          
          break
      }
      this.showSelectDay = false
    },
    changeCheckType (v) {
      this.checkType = v
      this.setTimeCheck = false
      switch (v) {
        case 1:
          this.customer()
          break
        case 2:
          this.customer(this.dateFormat(new Date() - (86400 * 1 * 1000)), this.dateFormat(new Date() - (86400 * 1 * 1000)))
          break
        case 3:
          const date = new Date()
          const year = date.getFullYear()
          const month = date.getMonth()
          this.customer(year + '-' + (month+1) + '-01', this.dateFormat(new Date()))
          break
      }
    },
    changeQunCheckType (v) {
      this.checkTypeQun = v
      this.setTimeCheckQun = false
      switch (v) {
        case 1:
          this.customerBase(this.dateFormat(new Date()), this.dateFormat(new Date()))
          break
        case 2:
          this.customerBase(this.dateFormat(new Date() - (86400 * 1 * 1000)), this.dateFormat(new Date() - (86400 * 1 * 1000)))
          break
        case 3:
          const date = new Date()
          const year = date.getFullYear()
          const month = date.getMonth()
          this.customerBase(year + '-' + (month+1) + '-01', this.dateFormat(new Date()))
          break
      }
    },
    changeCheckTypeDepartment (v) {
      this.checkTypeDepartment = v
      this.setTimeCheckDepartment = false
      switch (v) {
        case 1:
          this.customerDepartment()
          break
        case 2:
          this.customerDepartment(this.dateFormat(new Date() - (86400 * 1 * 1000)), this.dateFormat(new Date() - (86400 * 1 * 1000)))
          break
        case 3:
          const date = new Date()
          const year = date.getFullYear()
          const month = date.getMonth()
          this.customerDepartment(year + '-' + (month+1) + '-01', this.dateFormat(new Date()))
          break
      }
    },
    changeQunCheckTypeDepartment (v) {
      this.checkTypeQunDepartment = v
      this.setTimeCheckQunDepartment = false
      switch (v) {
        case 1:
          this.customerBaseDepartment()
          break
        case 2:
          this.customerBaseDepartment(this.dateFormat(new Date() - (86400 * 1 * 1000)), this.dateFormat(new Date() - (86400 * 1 * 1000)))
          break
        case 3:
          const date = new Date()
          const year = date.getFullYear()
          const month = date.getMonth()
          this.customerBaseDepartment(year + '-' + (month+1) + '-01', this.dateFormat(new Date()))
          break
      }
    },
    tabChange (name, title) {
      if (name == 2) {
        setTimeout(() => {
          this.categoryCategory()
          if (this.userPermission < 2) {
            this.roomDepartmentEchart()
          }
        })
      }
    },
    // 获取个人客户统计数据
    customer(startDay, endDay) {
      contactStatistics({
        day: startDay || this.dateFormat(new Date()),
        endDay: endDay || this.dateFormat(new Date())
      }).then(res => {
        this.dayStatistics = {
          date: [],
          add: [],
          loss: []
        }
        res.data.dayStatistics.reverse().map(item => {
          this.dayStatistics.date.push(item.day)
          this.dayStatistics.add.push(item.add)
          this.dayStatistics.loss.push(item.loss)
        })
        // this.dayStatistics.add = [0, 1, 4, 0, 3, 5, 0, 1]
        // this.dayStatistics.loss = [2, 0, 3, 5, 0, 0, 2, 1]

        this.Category()
        this.statistics = res.data.statistics

      })
    },
    // 获取部门客户统计数据
    customerDepartment (startDay, endDay) {
      departmentStatistics({
        day: startDay || this.dateFormat(new Date()),
        endDay: endDay || this.dateFormat(new Date())
      }).then(res => {
        this.departmentStatistics = res.data.statistics
        this.departmentEchartData = {
          date: [],
          add: [],
          loss: []
        }
        res.data.dayStatistics.reverse().map(item => {
          this.departmentEchartData.date.push(item.day)
          this.departmentEchartData.add.push(item.add)
          this.departmentEchartData.loss.push(item.loss)
        })
        this.departmentEchart()
      })

      this.page = 1
      this.list = []
      this.finished = false
      this.onLoad(startDay, endDay)
    },
    // 获取个人群客户统计数据
    customerBase(startDay, endDay) {
      roomStatistics({
        day: startDay || this.dateFormat(new Date()),
        endDay: endDay || this.dateFormat(new Date())
      }).then(res =>{
        this.roomStatistics = res.data.statistics
        this.chartCategory = {
          date: [],
          add: [],
          loss: [],
          eoss: []
        }
        res.data.dayStatistics.reverse().map(item => {
          this.chartCategory.date.push(item.day)
          this.chartCategory.add.push(item.addContact)
          this.chartCategory.loss.push(item.addRoom)
          this.chartCategory.eoss.push(item.lossContact)
        })
        if(this.active == '2'){
          this.categoryCategory()
        }
      })
    },
    // 获取部门客户群统计数据
    customerBaseDepartment (startDay, endDay) {
      departmentRoomStatistics({
        day: startDay || this.dateFormat(new Date()),
        endDay: endDay || this.dateFormat(new Date())
      }).then(res =>{
        this.roomDepartmentStatistics = res.data.statistics
        this.roomDepartmentEchartData = {
          date: [],
          add: [],
          loss: [],
          eoss: []
        }
        res.data.dayStatistics.reverse().map(item => {
          this.roomDepartmentEchartData.date.push(item.day)
          this.roomDepartmentEchartData.add.push(item.addContact)
          this.roomDepartmentEchartData.loss.push(item.addRoom)
          this.roomDepartmentEchartData.eoss.push(item.lossContact)
        })
        if(this.active == '2'){
          this.roomDepartmentEchart()
        }
      })
    },
    // 个人客户图表
    Category() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.getInstanceByDom(document.getElementById('lineEchart'))
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById('lineEchart'))
      }
      // 绘制图表
      myChart.setOption({
        legend: {
          data: ['新增', '流失']
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: this.dayStatistics.date
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '新增',
            data: this.dayStatistics.add,
            type: 'line',
            smooth: true
          },
          {
            name: '流失',
            data: this.dayStatistics.loss,
            type: 'line',
            smooth: true
          }
        ]
      });
    },
    // 个人群客户图表
    categoryCategory() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.getInstanceByDom(document.getElementById('lineEchartQun'))
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById('lineEchartQun'))
      }
      // 绘制图表
      myChart.setOption({
        legend: {
          data: ['新入群人数', '新增群数', '退群人数']
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: this.chartCategory.date
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '新入群人数',
            data: this.chartCategory.add,
            type: 'line',
            smooth: true
          },
          {
            name: '新增群数',
            data: this.chartCategory.loss,
            type: 'line',
            smooth: true
          },
          {
            name: '退群人数',
            data: this.chartCategory.eoss,
            type: 'line',
            smooth: true
          }
        ]
      });
    },
    // 部门客户图表
    departmentEchart () {
      let myChart = this.$echarts.getInstanceByDom(document.getElementById('departmentStatisticsEchart'))
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById('departmentStatisticsEchart'))
      }
      // 绘制图表
      myChart.setOption({
        legend: {
          data: ['新增', '流失']
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: this.departmentEchartData.date
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '新增',
            data: this.departmentEchartData.add,
            type: 'line',
            smooth: true
          },
          {
            name: '流失',
            data: this.departmentEchartData.loss,
            type: 'line',
            smooth: true
          }
        ]
      });
    },
    // 部门客户群图表
    roomDepartmentEchart () {
      let myChart = this.$echarts.getInstanceByDom(document.getElementById('roomDepartmentEchart'))
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById('roomDepartmentEchart'))
      }
      // 绘制图表
      myChart.setOption({
        legend: {
          data: ['新入群人数', '新增群数', '退群人数']
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: this.roomDepartmentEchartData.date
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '新入群人数',
            data: this.roomDepartmentEchartData.add,
            type: 'line',
            smooth: true
          },
          {
            name: '新增群数',
            data: this.roomDepartmentEchartData.loss,
            type: 'line',
            smooth: true
          },
          {
            name: '退群人数',
            data: this.roomDepartmentEchartData.eoss,
            type: 'line',
            smooth: true
          }
        ]
      });
    },
    // 日期格式化
    dateFormat(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      return Y + M + D
    },
    dateFormatStr (time) {
      var Y = time.getFullYear() + '-';
      var M = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-';
      var D = (time.getDate() < 10 ? '0' + time.getDate() : time.getDate());
      return Y + M + D
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
  },
  mounted() {
    var that = this
    setTimeout(function () {
      that.customer()
      that.customerBase()
      // 管理员
      if (that.userPermission < 2) {
        that.customerDepartment(that.dateFormat(new Date() - (86400 * 1 * 1000)), that.dateFormat(new Date() - (86400 * 1 * 1000)))
        that.customerBaseDepartment(that.dateFormat(new Date() - (86400 * 1 * 1000)), that.dateFormat(new Date() - (86400 * 1 * 1000)))
      }
    }, 1)
  },
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 9px;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 8px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/.van-action-sheet {
  max-height: 98%;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 ;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    background: #fff;
    min-height: 92%;
    padding-top: 5px;

    .van-picker {
      margin-bottom: 20px;
    }

    .showSelectDayBtn {
      padding: 10px 5px;
      display: flex;

      button {
        flex: 1;
        margin:  0 5px;
      }
    }

    .time {
      font-weight: bolder !important;
      color: #444 !important;
    }

    img {
      width: 22px;
      height: 22px;
    }

    .mgt16 {
      margin-top: 8px;
    }

    .basecard {
      background-color: #fff;
      padding: 0 0 24px 0;

      .header {
        height: 65px;
        padding: 0 15px 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header__title {
          line-height: 21px;
          font-weight: 500;
          color: #000;
        }

        .header__right {
          // flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .svg-icon {
            vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden
          }

          .switch {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .switch__item--active {
              font-weight: 500;
              color: #000;
            }

            .switch__item {

              padding: 0 3px;
              margin-left: 7px;
              color: #8c8c8c;
              font-size: 12px;
            }
          }
        }

      }

      .databox {
        margin: 0 16px;
        background-color: #F1f2f5;
        border-radius: 15px;
        

        .list {
          
          padding: 36px 0;
          overflow: hidden;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          padding-top: 30px;
          padding-bottom: 30px;
          

          .list__item__desc {
            font-size: 11px;
            line-height: 16px;
            color: #545454;
          }

          .list__item__count {
            font-size: 20px;
            line-height: 21px;
            margin-bottom: 4px;
            font-weight:  500;
            color: #000;
            // text-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          }

          .mgb40 {
            margin-bottom: 20px;
          }

          .list__item {
            width: 30%;
            text-align: center;
          }
        }
      }

      

      .echartBox {
        border: 1px solid #F1f2f5;
        border-radius: 15px;
        // margin-top: 40px;
        margin: 40px 16px 20px;
        // background-color: #F1f2f5;
        padding-top: 25px;
      }

      .chart__box {
        position: relative;
        height: 250px;

        .charts__tips {
          font-size: 10px;
          padding: 5px 16px 0;
          text-align: right;
          color: #b2b2b2;
        }
      }

      .content {
        padding: 0 16px;
        background-color: #fff;

        .rank {
          .header {
            position: relative;
            padding: 18px 28px 18px 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 24px;
            font-weight: 500;
            border-radius: 15px 15px 0 0;
            color: #fff;
            background-color: #294ba3;

            &:after {
              content: " ";
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 8px solid #fff;
              position: absolute;
              bottom: 0;
              left: 79px;
            }

            .header__title {
              line-height: 0.56rem;
              font-weight: 500;
              color: #fff;
            }

            .header__time {
              padding-left: 32px;
              position: relative;

              &:after {
                border-left-width: 1px !important;
                position: absolute;
                box-sizing: border-box;
                content: " ";
                pointer-events: none;
                top: -50%;
                right: -50%;
                bottom: -50%;
                left: -50%;
                border: 0 solid #f2f4f8;
                -webkit-transform: scale(.5) !important;
                transform: scale(.5);
              }
            }
          }
        }
      }
      .yesterdayAdd{
        padding: 13px 15px;
        min-height: 120px;
        border: 1px solid #f2f1f1;
        border-radius: 0 0 16px 16px;
        color: #000;
        font-size: 14px;
        div{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    /deep/.van-cell-group {
      margin: 0;
    }

    /deep/.van-hairline--top-bottom::after {
      border-width: 0
    }

    .list-item {
      margin-bottom: 16px;
      // background: #fff;
      // box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);
          border: 1px solid #F1f2f5;

      /deep/.van-cell {
        background-color: #FBFBFB;
      }

      &-title {
        display: flex;
        align-items: center;

        .van-image {
          margin-right: 8px;
        }
      }

      &-label {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
      }

      &-value {
        
      }
    }
  }
}
</style>
