<template>
  <div>
    <Popup
      v-model:show="transferPop"
      round
      position="bottom"
      closeable
      :close-on-click-overlay="false"
      :style="{ height: '490px' }">
      <!--      上一步-->
      <div style="position: relative;height: 100%;" v-show="stepShow==1">
        <div style="font-size: 14px;text-align: center;padding-top: 15px;">
          <div>转接客户</div>
          <div style="font-size: 13px;color: #ACACAC;">可选中的客户转接给其他员工，进行后续服务</div>
        </div>
        <Divider />
        <div class="transfer_page show_staff">
          <NoticeBar color="#BD7943" background="#FFF7F0" left-icon="info-o">
            90天内客户只能被转接两次
          </NoticeBar>
          <div style="display: flex;margin-top: 15px;">
            <div class="row_name">选择员工</div>
          </div>
          <van-search
            placeholder="搜索"
            @search="searchEmployee"
            v-model="employeeName"
            @input="clearInput"
            :clearable="false"
          />
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
          >
            <van-radio-group v-model="checkedStaff">
              <van-cell v-for="(item,index) in staffArray" :key="index">
                <div class="transfer_flex" style="cursor: pointer;" @click="selectEmployee(index)">
                  <div style="display: flex;">
                    <img :src="item.avatar" alt="" style="width: 40px;height: 40px;">
                    <span style="margin-left: 10px;line-height: 50px;">{{ item.name }}</span>
                  </div>
                  <van-radio :name="index"></van-radio>
                </div>
              </van-cell>
            </van-radio-group>
          </van-list>
        </div>
        <div class="foot_btn_row">
          <van-button type="primary" block @click="determineBtn">确定</van-button>
        </div>
      </div>
      <!--      下一步-->
      <div v-show="stepShow==2">
        <div style="font-size: 15px;text-align: center;padding-top: 15px;">转接客户给{{ replaceName }}</div>
        <Divider />
        <div class="transfer_page">
          <NoticeBar color="#BD7943" background="#FFF7F0" wrapable left-icon="info-o" style="font-size: 13px;">
            转接后客户将在24小时后成功转接，若客户拒绝，本次转接将不会生效
          </NoticeBar>
          <div class="transfer_flex" style="margin-top: 15px;">
            <div style="font-size: 14px;color: #ACACAC;">客户将收到以下消息</div>
            <div style="color: #329CFF;cursor: pointer;" @click="modifyBtn" v-if="!showModify">修改</div>
          </div>
          <div class="show-tips-info">
            <div class="show_tip_news" v-if="!showModify">
              <div class="transfer_flex">
                <img src="../../../assets/contactImg.svg" alt="" style="width: 40px;height: 40px;">
                <div class="tip_content">{{ tipWord }}</div>
              </div>
              <div style="text-align: center;margin-top: 15px;font-size: 15px;color: #A6A8A9;">
                由于工作变更，<span style="color: #7D8DAC">{{ replaceName }}</span>将在24小时后添加为你的企业微信联系人，继续为你提供服务。
              </div>
              <div style="text-align: center;margin-top: 10px;font-size: 15px;color:  #7D8DAC;">暂不添加</div>
            </div>
            <div style="position: relative;" v-if="showModify">
              <textarea class="textarea" v-model="modifyTips"></textarea>
              <div class="textarea_btn">
                <span style="color: #545454;cursor: pointer;" @click="removeBtn">取消</span>
                <span style="color: #319BFE;cursor: pointer;" @click="saveTipSentence">保存</span>
              </div>
            </div>
            <div style="margin-top: 10px;font-size: 13px;color: #878787;">提示：客户的客户画像相关信息将会同步继承至接替员工</div>
          </div>
          <div class="transfer_flex transfer_foot_btn">
            <van-button plain type="primary" @click="returnBtn">返回</van-button>
            <van-button type="primary" @click="confirmTransfer">确认转接</van-button>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { EmployeeApi } from '@/api/employee'
// eslint-disable-next-line no-unused-vars
import { ContactTransferApi } from '@/api/contactTransfer'
import { Divider, Popup, NoticeBar, Toast } from 'vant'
export default {
  components: {
    Divider, Popup, NoticeBar, Toast
  },
  data () {
    return {
      transferPop: false,
      finished: true,
      loading: false,
      checkedStaff: -1,
      staffArray: [],
      stepShow: 1,
      tipWord: '',
      modifyTips: '',
      showModify: false,
      replaceName: '',
      employeeName: ''
    }
  },
  methods: {
    selectEmployee (index) {
      this.checkedStaff = index
    },
    // 清空搜索框
    clearInput () {
      if (this.employeeName == '') {
        this.getEmployeeList()
      }
    },
    // 搜索员工
    searchEmployee () {
      this.getEmployeeList()
    },
    show (contactId) {
      this.contactId = contactId
      this.transferPop = true
      this.getEmployeeList()
    },
    getEmployeeList () {
      EmployeeApi.index({
        name: this.employeeName
      }).then((res) => {
        this.staffArray = res.data
      })
    },
    // 确认转接
    confirmTransfer () {
      if (this.tipWord == '') {
        Toast('客户收到的提示不能为空')
        return false
      }
      ContactTransferApi.transfer({
        contactId: this.contactId,
        employeeId: this.staffArray[this.checkedStaff].id,
        content: this.tipWord
      }).then((res) => {
        Toast('转接成功')
        this.transferPop = false
        this.checkedStaff = -1
        this.stepShow = 1
      })
    },
    // 保存
    saveTipSentence () {
      this.tipWord = JSON.parse(JSON.stringify(this.modifyTips))
      this.showModify = false
    },
    // 取消按钮
    removeBtn () {
      this.showModify = false
    },
    // 修改按钮
    modifyBtn () {
      this.showModify = true
      this.modifyTips = JSON.parse(JSON.stringify(this.tipWord))
    },
    // 返回按钮
    returnBtn () {
      this.stepShow--
    },
    // 确定按钮
    determineBtn () {
      if (this.checkedStaff == -1) {
        Toast('请选择要转接的员工')
        return false
      }
      this.replaceName = this.staffArray[this.checkedStaff].name
      this.tipWord = '您好，您的服务已升级，后续将由我的同事@' + this.replaceName + '接替我的工作，继续为您服务。'
      this.stepShow++
    }
  }
}
</script>
<style scoped lang="less">
.transfer_flex{
  display: flex;
  justify-content:space-between;
}
.transfer_page{
  padding: 0 35px;
  font-size: 28px;
  color: #333;
}
.show_staff{
  height: 720px;
  overflow-y: scroll;
}
.row_name{
  font-size: 29px;
  border-left: 7px solid #1890FF;
  height: 35px;
  line-height: 40px;
  padding-left: 10px;
}
.foot_btn_row{
  position: absolute;
  bottom: 10px;
  left: 35px;
  width: calc(100% - 70px);
}
.show_tip_news{
  padding: 50px 30px 30px;
  background: #F0F0F0;
  margin-top: 15px;
  border-radius: 5px;
}
.tip_content{
  width: 100%;
  background: #fff;
  padding: 20px 40px;
  margin-left: 20px;
}
.transfer_foot_btn{
  margin-top: 10px;
  margin-bottom: 20px;
  button{
    width: calc(50% - 10px);
    height: 75px;
  }
}
.textarea{
  margin-top: 15px;
  width: calc(100% - 60px);
  height: 400px;
  padding: 50px 30px 50px 30px;
  resize:none;
  border: 0;
  background: #F0F0F0;
}
.textarea_btn{
  position: absolute;
  bottom: 30px;
  right: 0;
  span{
    margin-right: 30px;
  }
}
.show-tips-info{
  min-height: 530px;
}
</style>
