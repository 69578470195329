<template>
  <div class="index">
    <div class="select">
<!--      <div style="white-space: nowrap;">-->
<!--        团队-->
<!--        <a-icon type="swap"/>-->
<!--      </div>-->
      <van-search
          v-model="serchVale"
          shape="round"
          placeholder="搜索"
          @search="search"
          @clear="clear"
      />
      <!--          :readonly="!sreenw"-->
      <van-icon v-if="sreenw" @click="onClickRight" style="font-size: 20px" name="filter-o"/>
    </div>

    <van-tabs v-model="actives" title-active-color="#1890ff" color="#1890ff" background="#f1f2f5">
      <van-tab title="客户">
        <div class="customer">
          <div v-if="!serchT">
            <div class="total" style="font-weight: 550;display: flex;align-items: center;justify-content: space-between">
              共{{pages.total}}个客户 
              <van-tag @click="checkData(3)" type="primary" size="large" :plain="checkDataType == 3 ? false : true">前天</van-tag>
              <van-tag @click="checkData(2)" type="primary" size="large" :plain="checkDataType == 2 ? false : true">昨天</van-tag>
              <van-tag @click="checkData(1)" type="primary" size="large" :plain="checkDataType == 1 ? false : true">今天</van-tag>
              <!-- <span>前天</span>
              <span>昨天</span>
              <span>今天</span> -->
              <a @click="checkMode = true" v-if="!checkMode" style="font-weight: normal">批量打标签</a>
              <a @click="checkMode = false" v-else style="font-weight: normal">取消</a>
            </div>
            <div class="list">
              <van-checkbox-group icon-size="15px" v-model="resultCustomer" ref="checkboxGroup">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    @load="onLoad"
                >
                  <div :class="{ 'choseItem' : resultCustomer.indexOf(index) != -1 }"
                       style="margin-top: 10px;border-radius: 7px;border: 1px solid transparent;"
                       v-for="(item, index) in userList" :key="index">
                    <div @click="userDetails(item)" class="list-item">
                      <!-- <img @click="pushUserEmployee(item.id, item.employeeId)" class="avater" :src="item.avatar" alt="">
                      <div @click="pushUserEmployee(item.id, item.employeeId)" class="mindel"> -->
                      <img class="avater" :src="item.avatar" alt="">
                      <div class="mindel">
                        <div class="item">
                          <div style="display: flex">
                            <h4>{{ item.name }}</h4>
                            <span>@微信</span>
                          </div>
                          <div>
                            <div>
                              所属员工：
                              <img :src="item.employeeAvatar || ''">
                              {{ item.employeeName }} <span v-if="item.addWayText">({{item.addWayText}})</span>
                            </div>
                            <div>
                              添加时间：{{ item.createTime }}
                            </div>
                            <div>
                              最后跟进：{{ item.lastFollowTime }}
                            </div>
                            <div style="display: flex;">
                              <span style="margin-right: 20px">意向等级：{{ item.stage || '未设置' }}</span>
                              <span>意向车型：{{ item.intentionModelText || '未设置' }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img v-if="!checkMode" class="news" src="../../assets/images/newMy.png">
                      <van-checkbox v-else :name="item"></van-checkbox>
                    </div>
                    <div class="bottom">
                      <div :class="{unset: item.zk}" class="bottom-list">
                        <!-- <div v-if="item.stage">{{item.stage}}</div>
                        <div v-if="item.intentionModelText">{{item.intentionModelText}}</div> -->
                        <div v-for="(i ,is) in item.tags" :key="is">{{i}}</div>
                      </div>
                      <div class="button" v-if="item.tagsl > 18" style="padding: 0 12px;">
                        <div v-if="!item.zk" @click="item.zk = true">
                          展开
                          <van-icon name="arrow-down"/>
                        </div>
                        <div v-else @click="item.zk = false">
                          收起
                          <van-icon name="arrow-up"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="finished && userList.length" slot="finished">无更多数据</div>
                </van-list>
                <van-empty
                  v-if="finished && !userList.length"
                  class="custom-image"
                  description="暂无数据"
                />
              </van-checkbox-group>
            </div>
          </div>
          <div v-else>
            <div class="total">
              共{{serListp.total}}个客户
              <a @click="checkMode = true" v-if="!checkMode" style="font-weight: normal">批量打标签</a>
              <a @click="checkMode = false" v-else style="font-weight: normal">取消</a>
            </div>
            <div class="list">
              <van-checkbox-group icon-size="15px" v-model="resultCustomer" ref="checkboxGroup">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    @load="onLoad"
                >
                  <div :class="{ 'choseItem' : resultCustomer.indexOf(index) != -1 }"
                       style="margin-top: 10px;border-radius: 7px;border: 1px solid transparent;"
                       v-for="(item, index) in serList" :key="index">
                    <div class="list-item" @click="userDetails(item)">
                      <img class="avater" :src="item.avatar" alt="">
                      <div class="mindel">
                        <div class="item">
                          <div style="display: flex">
                            <h4>{{ item.name }}</h4>
                            <span>@微信</span>
                          </div>
                          <div>
                            <div>
                              所属客服：
                              <img :src="item.employeeAvatar || ''">
                              {{ item.employeeName }}
                            </div>
                            <div style="display: flex">
                              <span>添加时间：{{ item.createTime }}</span>    
                            </div>
                          </div>
                        </div>
                      </div>
                      <img v-if="!checkMode" class="news" src="../../assets/images/newMy.png">
                      <van-checkbox v-else :name="index"></van-checkbox>
                    </div>
                    <div class="bottom">
                      <div :class="{unset: item.zk}" class="bottom-list">
                        <div v-for="(i ,is) in item.tags" :key="is">{{i}}</div>
                      </div>
                      <div class="button" v-if="item.tagsl > 18" style="padding: 0 12px;">
                        <div v-if="!item.zk" @click="item.zk = true">
                          展开
                          <van-icon name="arrow-down"/>
                        </div>
                        <div v-else @click="item.zk = false">
                          收起
                          <van-icon name="arrow-up"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="finished && serList.length" slot="finished">无更多数据</div>
                </van-list>
                <van-empty
                  v-if="finished && !serList.length"
                  class="custom-image"
                  description="暂无数据"
                />
              </van-checkbox-group>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="客户群" class="groups">
        <van-list
            v-model="roomLoading"
            :finished="roomFinished"
            @load="roomLoad"
        >
          <div>
            <div class="total"
                 style="font-weight: 550;display: flex;align-items: center;justify-content: space-between">
              共{{baseList.length}}个群聊
            </div>
            <div v-for="(item,index) in baseList" :key="index" class="group">
              <div class="flex-row justify-start info">
                <div class="col-1">
                  <div class="flex-row"><img
                      src="../../assets/images/avatar-room.svg"
                      class="group-cover">
                    <div class="flex-col align-left">
                      <div class="flex-row"><span class="bold col-1">{{ item.name }}<span>({{item.total}})</span></span>
                        <span></span>
                      </div>
                      <div class="group-owner-text">
                        <span>群主：</span> <span class="qw-open-data __qwOpenData__" style=""> {{ item.ownerName }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <a-icon :data-clipboard-text="item.name" @click="copy" class="coopy" type="copy" style="font-size: 14.63px;color: #c7c7c7;"/>
              </div>
              <div class="data flex-row">
<!--                <div class="col-1"><span>{{item.createTime}}</span></div>-->
<!--                                <div class="col-1"><span><label>今日入群：</label> 0</span> <span><label>今日退群：</label> 0</span></div>-->
<!--                <span class="clickable mark-tag-btn">打标签</span>-->
              </div>
            </div>
          </div>
          <div v-if="roomFinished && baseList.length" slot="finished">无更多数据</div>
        </van-list>
        <van-empty
          v-if="roomFinished && !baseList.length"
          class="custom-image"
          description="暂无数据"
        />
      </van-tab>
    </van-tabs>

    <!--  多选操作  -->
    <div v-if="checkMode">
      <div class="select-all">
        <van-checkbox v-model="selectAll" icon-size="15px">全选</van-checkbox>
        <span class="length">已选择{{ resultCustomer.length }}个客户</span>
        <van-button @click="$refs.customerLabel.$show(tagsValue)" style="height: 31px;" type="info">打标签</van-button>
      </div>
    </div>
    <!--  添加标签  -->
    <addTags ref="tags"/>
    <van-tabbar v-model="active" class="tab">
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/home.png"/>
          <img v-else src="../../assets/images/home-active.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customer" icon="search">
        <span>客户</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/customer-active.png"/>
          <img v-else src="../../assets/images/customer.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/tool/index" icon="setting-o">
        <span>运营工具</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/function-active.png"/>
          <img v-else src="../../assets/images/function.png">
        </template>
      </van-tabbar-item>
      <!-- <van-tabbar-item replace to="/my" icon="setting-o">
        <span>我的</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/my-active.png"/>
          <img v-else src="../../assets/images/my.png">
        </template>
      </van-tabbar-item> -->
    </van-tabbar>
    <customerLabel ref="customerLabel" @change="userTagsChose"/>

    <!-- 筛选 -->
    <van-popup v-model="showRight" position="right" :style="{ height: '100%', width: '85%' }">
      <div class="screen">
        <div class="list-item">
          <div class="item-title">
            所属部门
          </div>
          <div @click="$refs.department.$show(departmentList)" style="margin-top: 10px;">
            <van-button style="height: 30px;" plain type="info">选择部门</van-button>
          </div>
          <div v-if="departmentList.length > 0" style="margin-top: 10px;">
            <van-tag style="margin-right: 5px" v-for="(item,index) in departmentList" :key="index" closeable plain  size="medium" type="primary" @close="departmentList.splice(index,1)">{{ item.title }}</van-tag>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            所属客服
          </div>
          <div @click="$refs.employee.$show(StandbyStaffList,'max')" class="item-content">
            {{ StandbyStaffList.length > 0 ? String(StandbyStaff) : '请选择客服' }}
            <van-icon name="arrow-down"/>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            客户等级
          </div>
          <div @click="$refs.stage.$show(stage,'max')" class="item-content">
            {{ stage.length > 0 ? stage[0].name : '请选择等级' }}
            <van-icon name="arrow-down"/>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            意向车型
          </div>
          <div @click="$refs.intentionModel.$show(intentionModel,'max')" class="item-content">
            {{ intentionModel.length > 0 ? intentionModel[0].name : '请选择车型' }}
            <van-icon name="arrow-down"/>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            系统标签
          </div>
          <div @click="$refs.systemTags.$show(systemTags)" style="margin-top: 10px;">
            <van-button style="height: 30px;" plain type="info">选择标签</van-button>
          </div>
          <div v-if="systemTags.length > 0" style="margin-top: 10px;">
            <van-tag style="margin-right: 5px" v-for="(item,index) in systemTags" :key="index" closeable plain  size="medium" type="primary" @close="systemTags.splice(index,1)">{{ item.name }}</van-tag>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            企业标签
          </div>
          <div @click="$refs.tags.$show(tagsList)" style="margin-top: 10px;">
            <van-button style="height: 30px;" plain type="info">选择标签</van-button>
          </div>
          <div v-if="tagsList.length > 0" style="margin-top: 10px;">
            <van-tag style="margin-right: 5px" v-for="(item,index) in tagsList" :key="index" closeable plain  size="medium" type="primary" @close="tagsList.splice(index,1)">{{ item.name }}</van-tag>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            添加时间
          </div>
          <!-- <div class="item-content" @click="$refs.time.$show()">
            <div @click="$refs.time.$show()" style="flex: 1;text-align: center">{{ sTime ? sTime : '开始时间' }}</div>
            -
            <div @click="$refs.time.$show()" style="flex: 1;text-align: center">{{ endTime ? endTime : '结束时间' }}</div>
          </div> -->
          <div class="item-content" @click="showSelectDay = true">
            <div style="flex: 1;text-align: center">{{ dateStr.startDay ? dateStr.startDay : '开始时间' }}</div>
            -
            <div style="flex: 1;text-align: center">{{ dateStr.endDay ? dateStr.endDay : '结束时间' }}</div>
          </div>
          
        </div>
        <!-- <div class="list-item">
          <div class="item-title">
            添加渠道
          </div>
          <div>
            <div v-for="(item,index) in qcode" :key="index" @click="activeq(index)" :class="{qd: item.chose}" class="item-content item-lable">
              {{ item.name }}
            </div>
          </div>
        </div> -->
        <div class="list-item">
          <div class="item-title">
            客户性别
          </div>
          <div>
            <div @click="codew = 0" :class="{qd: codew == 0}"  class="item-content item-lable" style="    padding: 0 26px;">
              全部
            </div>
            <div @click="codew = 1" :class="{qd: codew == 1}" class="item-content item-lable" style="    padding: 0 26px;">
              男
            </div>
            <div @click="codew = 2" :class="{qd: codew == 2}"  class="item-content item-lable" style="    padding: 0 26px;">
              女
            </div>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            流失状态
          </div>
          <div>
            <div @click="lose = 0" :class="{qd: lose == 0}" class="item-content item-lable">
              全部
            </div>
            <div @click="lose = 1" :class="{qd: lose == 1}" class="item-content item-lable">
              已流失
            </div>
            <div @click="lose = 2" :class="{qd: lose == 2}" class="item-content item-lable">
              未流失
            </div>
          </div>
        </div>
        <div style="justify-content: space-around; width: 100%;display: flex; justify-content: space-between; margin-bottom: 20px">
          <van-button @click="screenReset" style="margin-right: 20px" block type="default">重置</van-button>
          <van-button @click="customer" block type="info">搜索</van-button>
        </div>
      </div>
    </van-popup>

    <!--  选择客户  -->
    <addCustomer ref="customer"/>
    <!--  选择企业标签  -->
    <tagsSelsct ref="tags" @change="tagsChose"/>
    <!--  时间选择  -->
    <addTime ref="time" @change="choseTime"/>
    <!-- 选择员工 -->
    <employeeIndex ref="employee" @change="userChange"/>
    <!-- 选择部门 -->
    <departmentSelect ref="department" @change="departmentChange"/>
    <!-- 选择等级 -->
    <stageSelect ref="stage" @change="stageChange"></stageSelect>
    <!-- 选择意向车型 -->
    <modelSelect ref="intentionModel" @change="intentionModelChange"></modelSelect>
    <!-- 选择系统标签 -->
    <tagsSelsct ref="systemTags" :is-system="1" @change="systemTagsChose"/>

    <!-- 日期 -->
    <van-action-sheet v-model="showSelectDay" :close-on-click-overlay="false">
      <van-datetime-picker
        v-model="sTime"
        type="date"
        title="开始日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <van-datetime-picker
        v-model="endTime"
        type="date"
        title="结束日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <div class="showSelectDayBtn">
        <van-button @click="clearSetectDay">取消</van-button>
        <van-button type="info" @click="setDate">确认</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCookie } from '@/plugins/utils'
import {batchCreateContactTag, contactIndex, workRoom} from '@/api/customer'
import { pushUserEmployee } from '@/plugins/utils'
import Clipboard from 'clipboard'

import addTags from "./components/addTags";
import customerLabel from "../channelCode/components/customerLabel";

import addCustomer from "../index/components/addCustomer";
import tagsSelsct from "../channelCode/components/customerLabel";
import employeeIndex from "../channelCode/components/employeeIndex";
import departmentSelect from "@/components/departmentSelect/index";
import addTime from "../channelCode/components/addTime";
import stageSelect from "../../components/stageSelect/";
import modelSelect from "../../components/modelSelect/";

export default {
  components: {
    addTags,
    customerLabel,

    addCustomer,
    tagsSelsct,
    addTime,
    employeeIndex,
    departmentSelect,
    stageSelect,
    modelSelect
  },
  computed:{
		...mapGetters([
			'userPermission'
		])
	},
  data() {
    return {
      pushUserEmployee,
      // 文字
      message: '',
      // tbas切换
      tabsChose: 0,
      actives: 0,
      active: 1,
      // 搜索条件
      serchVale: '',
      
      resultCustomer: [],
      checkMode: false,
      // 全选
      selectAll: "",
      // 列表加载
      loading: false,
      finished: false,
      page: 1,
      userList: [],
      pages: {
        perPage: '',
        total: '',
        totalPage: ''
      },
      tagsValue:[],

      dataLiS:{
        page: '',
        perPage: "",
      },
      serList:[],
      serchT: false,
      serListp:{},

      // 客户群
      baseList:[],
      roomPage: 1,
      roomPages: {
        total: '',
        totalPage: ''
      },
      roomLoading: false,
      roomFinished: false,
      sreenw: true,

      // 筛选
      showRight: false,
      screen: {},
      StandbyStaffList: [],
      StandbyStaff:[],
      stage: [],
      departmentList: [],
      tagsList: [],
      systemTags: [],
      intentionModel: [],

      showSelectDay: false,
      dateStr: {
        startDay: '',
        endDay: ''
      },
      sTime: new Date(),
      endTime: new Date(),
      //渠道
      qcode: [{chose: true,addWay:2,name: '搜索手机号'},{chose: false,addWay:5,name: '手机通讯录'},{chose: false,addWay:8,name: '安装第三方应用时添加的客服人员'},{chose: false,addWay:202,name: '管理员/负责人分配'},{chose: false,addWay:1003,name: '裂变引流'}],
      // 性别
      codew: 0,
      lose: 0,

      // 今天昨天前天
      checkDataType: 0
    }
  },
  watch: {
    selectAll(e) {
      if (e) {
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.checkboxGroup.toggleAll();
      }
    },
    actives(e){
      if(this.actives == 1){
        this.sreenw = false
        this.serchVale = ''
      }else{
        this.sreenw = true
      }
    },
    serchVale(){
      if(!this.serchVale){
        this.clear()
      }
    }
  },
  created() {
    // this.customerBase()
    if (this.$route.query.content){
      this.dataLiS = JSON.parse(this.$route.query.content)
    }
  },
  methods: {
    checkData (type) {
      if (this.checkDataType != type) {
        this.checkDataType = type
        const GetDateStr = function(AddDayCount) {
          var dd = new Date();
          dd.setDate(dd.getDate() + AddDayCount);//获取AddDayCount天后的日期
          var y = dd.getFullYear();
          var m = dd.getMonth() + 1;//获取当前月份的日期
          var d = dd.getDate();
          return y + '-' + m + '-' + d
        }
        switch (type) {
          // 今天
          case 1:
            console.log(GetDateStr(0))
            this.dateStr.startDay = GetDateStr(0)
            this.dateStr.endDay = GetDateStr(0)
            break
          // 昨天
          case 2:
            console.log(GetDateStr(-1))
            this.dateStr.startDay = GetDateStr(-1)
            this.dateStr.endDay = GetDateStr(-1)
            break
          // 前天
          case 3:
            console.log(GetDateStr(-2))
            this.dateStr.startDay = GetDateStr(-2)
            this.dateStr.endDay = GetDateStr(-2)
            break
        }
        this.screen.startTime = this.dateStr.startDay
        this.screen.endTime = this.dateStr.endDay
        this.page = 1
        this.dataLiS = {}
        this.finished = false
        this.userList = []
        this.showRight = false
        this.onLoad()
      } else {
        this.checkDataType = 0
        this.dateStr.startDay = ''
        this.dateStr.endDay = ''
        this.screen.startTime = ''
        this.screen.endTime = ''
        this.page = 1
        this.dataLiS = {}
        this.finished = false
        this.userList = []
        this.showRight = false
        this.onLoad()
      }
    },
    dateFormatStr (time) {
      var Y = time.getFullYear() + '-';
      var M = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-';
      var D = (time.getDate() < 10 ? '0' + time.getDate() : time.getDate());
      return Y + M + D
    },
    setDate () {
      let startDay = this.dateFormatStr(this.sTime)
      let endDay = this.dateFormatStr(this.endTime)
      this.dateStr.startDay = startDay
      this.dateStr.endDay = endDay
      this.showSelectDay = false
    },
    clearSetectDay () {
      this.showSelectDay = false
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
    onClickRight() {
      this.showRight = true
    },
    screenReset () {
      this.screen = {}
      this.StandbyStaffList = []
      this.StandbyStaff =[]
      this.stage = []
      this.departmentList =[]
      this.tagsList = []
      this.systemTags = []
      this.intentionModel = []
      this.sTime = ''
      this.endTime = ''

      this.page = 1
      this.dataLiS = {}
      this.finished = false
      this.userList = []
      this.showRight = false
      this.onLoad()
    },
    customer(){
      const content = {
        gender: this.codew,
        status: this.lose
      }
      if (this.StandbyStaffList[0]) {
        content.employeeId = this.StandbyStaffList[0].id
      }
      if (this.departmentList.length > 0) {
        content.departmentId = []
        for(var i = 0; i < this.departmentList.length; i++){
          content.departmentId.push(this.departmentList[i].id)
        }
      }
      if (this.tagsList.length > 0) {
        content.tags = []
        for(var i = 0; i < this.tagsList.length; i++){
          content.tags.push(this.tagsList[i].id)
        }
      }
      if (this.systemTags.length > 0) {
        content.systemTags = []
        for(var i = 0; i < this.systemTags.length; i++){
          content.systemTags.push(this.systemTags[i].id)
        }
      }
      if (this.stage && this.stage[0]) {
        content.stage = this.stage[0].id
      }
      if (this.intentionModel && this.intentionModel[0]) {
        content.intentionModelId = this.intentionModel[0].id
      }
      if (this.dateStr.startDay && this.dateStr.endDay) {
        content.startTime = this.dateStr.startDay
        content.endTime = this.dateStr.endDay
      }
      console.log(content)
      this.screen = content

      this.page = 1
      this.dataLiS = {}
      this.finished = false
      this.userList = []
      this.showRight = false
      this.onLoad()
    },
    // 员工
    userChange(data, info) {
      this.StandbyStaffList = data
      this.StandbyStaff = data.map(i=>i.name)
    },
    tagsChose(data){
      this.tagsList = data
      console.log(data)
    },
    systemTagsChose (data) {
      this.systemTags = data
      console.log(data)
    },
    departmentChange (data) {
      this.departmentList = data
      console.log(data)
    },
    stageChange (data) {
      this.stage = data
      console.log(data)
    },
    intentionModelChange (data) {
      console.log(data)
      this.intentionModel = data
    },
    choseTime(data){
      this.sTime = data.time.split("-")[0]
      this.endTime = data.time.split("-")[1]
    },
    activeq(i){
      this.qcode.forEach(i=>{
        return i.chose = false
      })
      this.qcode[i].chose = true
    },
    routerPush(item) {
      let arr = location.hostname.split(".")
      arr[0] = 'work'
      let urlName = arr.join('.')
      var url = 'https://' + urlName + '/contact?agentId=' + getCookie('workbench_agentId') + '&contactId='+item.id + '&employeeId=' + item.employeeId
      window.location.href = url
    },
    userTagsChose(data) {
      const tagIds = data.map(i=>{
        return i.id
      })
      const contactIds = this.resultCustomer.map(i=>i.id)
      const datas = {
        tagIds,
        contactIds
      }
      batchCreateContactTag(datas).then(res=>{
        this.$message.success('操作成功')
        this.selectAll = []
        this.checkMode = false
      })
    },
    roomLoad (e) {
      workRoom({name: e}).then(res=>{
        this.baseList.push(...res.data.list)
        this.roomPages = res.data.page
        if(this.baseList.length == res.data.page.total){
          this.roomFinished = true
          this.roomLoading = false
        }else{
          this.roomPage++
          this.roomFinished = false
          this.roomLoading = false
        }
      })
    },
    // 列表加载
    onLoad() {
      for (var k in this.screen) {
        this.dataLiS[k] = this.screen[k]
      }
      this.dataLiS.page = this.page
      this.dataLiS.perPage =  10
      this.dataLiS.name = this.serchVale
      contactIndex(this.dataLiS).then(res=>{
        res.data.list.forEach(i=>{
          i.zk = false
          i.tagsl = String(i.tags).length
          i.createTime = i.createTime.split(" ")[0]
        })

        this.userList.push(...res.data.list)
        this.pages = res.data.page
        if(res.data.list.length < 10){
          this.finished = true
          this.loading = true
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
      })
    },
    customerBase(e){
      this.roomPage = 1
      this.baseList = []
      this.roomLoad(e)

      // workRoom({name: e}).then(res=>{
      //   this.baseList = res.data.list
      // })
    },
    search(){
      if(this.actives == 0){
        this.dataLiS.page = 1
        this.dataLiS.perPage =  10
        this.dataLiS.name = this.serchVale
        this.serchT = true
        contactIndex(this.dataLiS).then(res=>{

          res.data.list.forEach(i=>{
            i.zk = false
            i.tagsl = String(i.tags).length
            i.createTime = i.createTime.split(" ")[0]
          })

          this.serListp = res.data.page
          this.serList = res.data.list
          this.finished = true

        })
      }else{
        this.customerBase(this.serchVale)
      }

    },
    clear(){
      console.log('fff')
      if(this.actives == 0){
        this.serchT = false
      }else{
        this.customerBase()
      }
    },
    copy(i){
      var clipboard = new Clipboard('.coopy')
      clipboard.on('success', e => {
        this.$message.success('复制成功')
        //  释放内存
        clipboard.destroy()
      })
    },
    userDetails(item){
      console.log(item)
      // return
      if (this.userPermission < 2) {
        this.$router.push('/contact/detail?contactId='+ item.id + '&employeeId=' + item.employeeId)
      } else {
        this.pushUserEmployee(item.id, item.employeeId)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-tabbar{
  box-sizing: content-box !important;
}
/deep/ .select .van-nav-bar__title {
  color: #fff !important;
}

/deep/ .select .van-field__body {
  color: #fff;
}

/deep/ .select .van-search {
  background: #4376ca;
  width: 100%;
}

/deep/ .select .van-search__content {
  background: rgba(255, 255, 255, .3);
  color: #fff;
}

/deep/ .select .van-icon-search {
  color: #fff
}

.showSelectDayBtn {
    padding: 10px 5px;
    display: flex;

    button {
      flex: 1;
      margin:  0 5px;
    }
  }

.index {
  font-size: 14px;
  height: auto;
  min-height: 100%;
  background: #F1f2f5;

  p {
    margin: 0 !important;
  }

  .fs14 {
    opacity: .7;
    font-weight: 400;
    font-size: 14px;
  }

  .fs16 {
    font-size: 12px;
  }

  .fs12 {
    font-size: 12px;
  }

  .fs24 {
    font-size: 24px;
  }

  .select {
    display: flex;
    align-items: center;
    background: #4376ca;
    padding: 8px 16px;
    box-sizing: border-box;
    color: #fff;
  }

  .bottom {
    .bottom-list {
      background: #fff;
      padding: 0 5px;
      font-size: 12px;
      max-height: 30px;
      overflow: hidden;
      padding-bottom: 10px;

      div {
        background: #f7f7f7;
        border: 1px solid #e9e9e9;
        display: inline-block;
        padding: 2px 3px;
        border-radius: 6px;
        margin: 0px 5px;
        margin-bottom: 7px;
      }
    }

    .button {
      padding: 0px 12px;
      background: #fff;
      padding-bottom: 5px !important;

      div {
        font-size: 13px;
        color: #8db1db;
      }
    }

    .unset {
      max-height: unset;
    }

    border-radius: 0 0 7px 7px;
    overflow: hidden;
  }

  .customer {
    img {
      width: 22px;
      height: 22px;
    }

    padding: 0px 16px;
    padding-bottom: 51px;

    .total {
      font-weight: 550;
      display: flex;
      align-items: center;
      
      >a {
        flex: 1;
        text-align: right;
      }

      .van-tag {
        margin-left: 10px;
      }
    }

  }

  .list {
    .choseItem {
      border: 1px solid #1890ff;
    }

    .list-item {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      background: #fff;
      padding: 10px 15px;
      border-radius: 7px 7px 0 0;
      .mindel{
        flex: 1;
        margin-left: 15px;
      }
      .avater {
        width: 50px;
        height: 50px;
        margin-top: 4px;
      }

      .item {
        //margin-left: -87px;

        div:nth-child(1) {
          h4 {
            font-weight: bold;
            max-width: 168px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          span {
            color: #5ec75d;
            margin-left: 5px;
          }
        }

        div:nth-child(2) {
          font-size: 12px;
          color: #6a6a6a;

          div:nth-child(1) {
            display: flex;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-right: 5px;
            }
          }
        }
      }

      .news {
        background: #e8f0ff;
        border-radius: 50%;
      }


    }
  }

  .groups {

    padding: 0 16px 51px;

    .group {
      margin-top: 10px;
      background: #f9f9f9;
      padding: 12.5px 12.5px 6.3px 14.584px;

      .info {
        padding-bottom: 12.5px;
        border-bottom: 1.03px solid #f2f2f2;

        .col-1 {
          min-width: 0;
        }
      }

      .flex-row.justify-start {
        -webkit-box-align: start;
        align-items: flex-start;
      }

      .flex-row {
        -webkit-box-pack: start;
        -webkit-box-align: center;
        align-items: center;
        display: -webkit-box;
        display: flex;
        justify-content: flex-start;
        padding: 0;

        .col-1 {
          -webkit-box-flex: 1;
          flex: 1;

          .flex-row {
            -webkit-box-pack: start;
            -webkit-box-align: center;
            align-items: center;
            display: -webkit-box;
            display: flex;
            justify-content: flex-start;
            padding: 0;

            .flex-col {
              padding-left: 8.334px;
              -webkit-box-flex: 1;
              flex: 1;
              min-width: 0;
              padding-right: 19.793px;

              .flex-row {
                max-width: 100%;

                .bold {
                  font-size: 13.55px;
                  font-weight: 600;
                  color: #333;
                  //line-height: 1.282125rem;
                  min-width: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  word-break: break-all;
                  -webkit-box-flex: 1;
                  flex: 1;
                }

                span {
                  font-size: 13.55px;
                  color: rgba(0, 0, 0, .45);

                }
              }

              .group-owner-text {
                font-size: 12.5px;
                color: rgba(0, 0, 0, .3);

                .qw-open-data {
                  word-break: break-all;
                }
              }
            }

            .align-left {
              -webkit-box-align: start;
              align-items: flex-start;
            }

            img {
              width: 38px;
              height: 38px;
            }
          }
        }
      }

      .data {
        margin-top: 5.209px;

        .col-1 {
          span {
            color: rgba(0, 0, 0, .65);
            font-size: 12.5px;

            label {
              color: rgba(0, 0, 0, .45);
            }
          }

          span:last-child {
            margin-left: 17.709px;
          }
        }

        .mark-tag-btn {
          background: #1890ff;
          border-radius: 4px;
          font-size: 12.5px;
          color: #fff;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1.042px 8.334px;
          display: inline-block;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }

  .select-all {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px 10px;
    justify-content: space-between;
    bottom: 50px;

    .length {
      color: rgba(0, 0, 0, .45);
      margin-left: -90px;
    }
  }

  .screen {
    height: 100%;
    padding: 15px;
    background-color: #fff;

    .list-item {
      margin-bottom: 20px;
    }

    .item-title {
      font-weight: bold;
    }

    .item-content {
      width: 100%;
      background-color: #fff;
      /* background: red; */
      border: 1px solid #E5E6E7;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      border-radius: 7px;
      margin-top: 10px;
    }

    .item-lable {
      display: inline-block;
      width: auto;
      align-items: center;
      line-height: 36px;
      margin: 10px 3px;
      border-radius: 0;
    }

    .qd{
      color: #1890ff!important;
      border-color: #1890ff!important;
      background-color: #f3f9ff!important;
    }
    
  }
}
</style>
