import request from '@/utils/request'

/**
 * 模板拉群
 */
export class RoomTemplatePullApi {
  // 建群模板-列表
  static index (params) {
    return request({
      url: '/roomTemplatePull/index',
      method: 'get',
      params
    })
  }

  // 添加建群模板
  static store (params) {
    return request({
      url: '/roomTemplatePull/store',
      method: 'post',
      data: params
    })
  }

  // 修改建群模板
  static update (params) {
    return request({
      url: '/roomTemplatePull/update',
      method: 'put',
      data: params
    })
  }

  // 删除建群模板
  static delete (params) {
    return request({
      url: '/roomTemplatePull/delete',
      method: 'delete',
      data: params
    })
  }

  // 详情建群模板
  static show (params) {
    return request({
      url: '/roomTemplatePull/show',
      method: 'get',
      params
    })
  }
}
