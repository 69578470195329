<template>
  <div class="index">
    <van-nav-bar
      :title="'部门员工'"
      left-text="返回"
      left-arrow
      fixed
      placeholder 
      @click-left="onClickLeft"
    />
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell-group inset v-for="(item, index) in list" :key="index" class="list-item" @click="checkDetail(item.id)">
          <van-cell style="align-items: center;">
            <template #title>
              <span class="custom-title list-item-title">
                <van-image
                  width="44"
                  height="44"
                  fit="cover"
                  round
                  :src="item.avatar"
                />
                <div>
                  {{ item.name }}
                </div>
              </span>
            </template>
            <template>
              总客户人数：{{ item.total_contact }} 人
            </template>
          </van-cell>
          <van-cell>
            <template #title>
              <span class="custom-title list-item-title">
                新增：
              </span>
            </template>
            <template>
              {{ item.new_num }} 人
            </template>
          </van-cell>
          <van-cell>
            <template #title>
              <span class="custom-title list-item-title">
                流失：
              </span>
            </template>
            <template>
              {{ item.loss_num }} 人
            </template>
          </van-cell>
        </van-cell-group>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('../../assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
    <!--  -->
    <van-dialog v-model="show" confirmButtonColor="#1989fa">
      <van-cell-group class="list-item" style="box-shadow: none">
        <!-- <van-cell style="align-items: center;" v-if="detailData.contact">
          <template #title>
            <span class="custom-title list-item-title">
              <van-image
                width="44"
                height="44"
                fit="cover"
                round
                :src="detailData.contact.avatar"
              />
              <div>
                {{ detailData.contact.name }}
                <div>
                  <van-tag v-if="detailData.status == 1" plain type="primary">已完成</van-tag>
                  <van-tag v-else plain type="warning">未完成</van-tag>
                </div>
              </div>
            </span>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <span class="custom-title list-item-title">
              跟进时间
            </span>
          </template>
          <template>
            {{ detailData.follow_time }}
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <span class="custom-title list-item-title">
              跟进内容
            </span>
          </template>
          <template>
            {{ detailData.follow_log }}
          </template>
        </van-cell> -->
      </van-cell-group>
      <div style="margin-bottom: 20px">
        <div style="font-size: 12px; padding: 0 15px; color: #999">点击查看大图</div>
        <van-grid :border="false" :column-num="3" style="padding: 0 6px;">
          <van-grid-item v-for="(item, index) in detailData.images" :key="index">
            <van-image @click="checkImage(index)" width="100%" height="100" fit="cover" :src="item + '?x-oss-process=image/resize,w_200/quality,q_50'" />
          </van-grid-item>
        </van-grid>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { employeeContactStatistics } from '../../api/pullNew'
import { Dialog } from 'vant'
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      serchValue: '',
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15,
    }
  },
  created () {
  },
  methods: {
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      employeeContactStatistics({
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.list.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.list.data || [])
      })
    },
    checkDetail (id) {
      ContactTaskApi.followShow({id}).then(res => {
        this.detailData = res.data
        this.show = true
      })
    },
    onSearch () {},
    clearSerch () {},
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  .content {

    /deep/.van-cell-group {
      margin: 0;
    }
    // background: #f4f5f7;
    padding: 16px 12px;

    .list-item {
      margin-bottom: 16px;
      background: #fff;
      box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

      &-title {
        display: flex;
        align-items: center;

        .van-image {
          margin-right: 8px;
        }
      }

      &-label {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
      }

      &-value {
        
      }
    }
    

  }

  
}
</style>
