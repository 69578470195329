<template>
  <div>
    <Popup
      v-model:show="followModify"
      round
      position="bottom"
      closeable
      :close-on-click-overlay="false"
      :style="{ height: '300px' }"
    >
      <div style="width: 100%;height: 100%;position: relative;">
        <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">修改跟进</div>
        <Divider />
        <div class="newly_row" v-if="modifyInfo.processTemplateName">
          <div style="color: #999;">
            模板记录：{{ modifyInfo.processTemplateName }}
          </div>
        </div>
        <div class="add_follow_page">
          <div class="text-box">
            <textarea
              class="textarea"
              placeholder="请输入要添加的跟进信息吧，添加成功后将出现在客户动态列表里~"
              v-model="modifyInfo.content"
            ></textarea>
          </div>
        </div>
        <div class="foot_row">
          <van-button type="primary" block @click="addProcessEvent">修改</van-button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Divider, Popup, Toast } from 'vant'
import dropDownCase from '@/components/dropDownCase/dropDownCase'
// eslint-disable-next-line no-unused-vars
import { ContactFollowApi } from '@/api/contactFollow'
export default {
  components: { Popup, Divider, Toast, dropDownCase },
  data () {
    return {
      followModify: false,
      // 备注内容
      content: '',
      modifyInfo: {}
    }
  },
  methods: {
    // 获取跟进详情
    getFollowInfo () {
      ContactFollowApi.infoApi({
        targetId: this.targetId
      }).then((res) => {
        this.modifyInfo = res.data
      })
    },
    // 修改
    addProcessEvent () {
      ContactFollowApi.updateApi({
        trackId: this.trackId,
        targetId: this.targetId,
        content: this.modifyInfo.content
      }).then((res) => {
        Toast('修改成功')
        this.followModify = false
        this.$emit('change', true)
      })
    },
    show (obj) {
      this.followModify = true
      this.trackId = obj.id
      this.targetId = obj.content.target_id
      // this.event = obj.event
      this.getFollowInfo()
    }
  }
}
</script>
<style scoped lang="less">
.text-box{
  display: flex;
  justify-content:center;
  margin-top: 45px;
  border: 1px solid #eee;
}
.textarea{
  //border: 0;
  background: none;
  outline: none;
  border: none;
  height: 200px;
  width: 100%;
  padding: 20px 30px 20px 30px;
  font-size: 26px;
  resize:none;
}
.add_follow_flex{
  display: flex;
  justify-content:space-between;
}
.add_follow_page{
  font-size: 26px;
  padding: 0 35px;
}
.newly_row{
  margin-top: 20px;
  font-size: 26px;
  padding: 0 50px 0 50px;
}
.foot_row{
  width: calc(100% - 80px);
  position: absolute;
  bottom: 30px;
  left: 40px;
}
.add_input{
  width: 100px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #D9D9D9;
  height: 40px;
}
.newly_input{
  border: 1px solid #D9D9D9;
  margin-left: 15px;
  padding-left: 10px;
  height: 50px;
}
.input_style{
  padding-left: 10px;
  height: 50px;
  border: 1px solid #D9D9D9;
}
.option_row{
  width: 270px;
  height: 50px;
  border: 1px solid #cccccc;
  position: relative;
}
.option_row select{
  position: absolute;
  top: 0;
  /*清除select的边框样式*/
  border: none;
  /*清除select聚焦时候的边框颜色*/
  outline: none;
  /*将select的宽高等于div的宽高*/
  width: 100%;
  height: 50px;
}
.option_row option{
  height: 10px;
}
</style>
