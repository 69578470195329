<template>
  <div>
    <Popup v-model:show="selectMemberPop" style="width: 90%;">
      <div class="select_page">
        <div style="color: #595959;">全部员工({{ employeeData.length }})</div>
        <van-search placeholder="请输入搜索关键词" />
        <div class="staff-list">
          <van-list
            :finished="finished"
            finished-text="没有更多了">
            <van-cell v-for="(item,index) in employeeData" :key="index">
              <div class="select_flex">
                <div style="display: flex;">
                  <img :src="item.avatar" alt="" style="width: 40px;height: 40px;">
                  <div style="margin-left: 10px;margin-top: 10px;">{{ item.name }}</div>
                </div>
                <van-checkbox v-model="item.checked" @click="checkEmployee(item)"></van-checkbox>
              </div>
            </van-cell>
          </van-list>
        </div>
        <van-button type="primary" block style="margin-top: 20px;margin-bottom: 20px;" @click="selectEmployeeBtn">确定</van-button>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Popup, Divider } from 'vant'
// eslint-disable-next-line no-unused-vars
import { EmployeeApi } from '@/api/employee'
export default {
  components: {
    Popup, Divider
  },
  data () {
    return {
      selectMemberPop: false,
      finished: true,
      checkedGroup: '',
      checked: '',
      employeeData: [],
      selectEmployee: [],
      employeeName: ''
    }
  },
  methods: {
    // 选中员工
    selectEmployeeBtn () {
      this.$emit('change', this.selectEmployee)
      this.selectMemberPop = false
    },
    show (employeeArr) {
      this.selectEmployee = []
      this.selectMemberPop = true
      this.selectEmployee = employeeArr.map((item, index) => {
        item.checked = true
        return JSON.stringify(item)
      })
      this.getEmployeeList()
    },
    getEmployeeList () {
      EmployeeApi.index({
        name: this.employeeName
      }).then((res) => {
        this.handleEmployeeData(res.data)
      })
    },
    // 处理员工数据
    handleEmployeeData (data) {
      data.forEach((item, index) => {
        item.checked = true
        const dataIndex = this.selectEmployee.indexOf(JSON.stringify(item))
        if (dataIndex == -1) {
          item.checked = false
        }
      })
      this.employeeData = data
    },
    // 选中员工
    checkEmployee (item) {
      const selectData = JSON.parse(JSON.stringify(item))
      selectData.checked = true
      const indexArr = this.selectEmployee.indexOf(JSON.stringify(selectData))
      if (indexArr == -1) {
        this.selectEmployee.push(JSON.stringify(selectData))
      } else {
        this.selectEmployee.splice(indexArr, 1)
      }
    }
  }
}
</script>
<style scoped lang="less">
.select_flex{
  display: flex;
  justify-content:space-between;
}
.select_page{
  padding: 35px 35px 15px 35px;
  font-size: 28px;
  color: #333;
}
.checkbox:checked {
  background: #1673ff;
  border: solid 1px #1673ff;
}
.checkbox {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #ffffff;
  border: solid 1px #dddddd;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 0.8rem;
  margin: 15px 0 0 0;
  padding: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: default;
  -webkit-appearance: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-transition: background-color ease 0.6s;
  transition: background-color ease 0.6s;
}
.checkbox:checked::after {
  content: '';
  top: 0.1rem;
  left: 0.09rem;
  position: absolute;
  background: transparent;
  border: #fff solid 2px;
  border-top: none;
  border-right: none;
  height: 0.12rem;
  width: 0.25rem;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.staff-list{
  width: 100%;
  height: 360px;
  overflow-y: scroll;
}
</style>
