<template>
  <div>
    <Popup
      v-model:show="endRepeatPop"
      round
      position="bottom"
      :close-on-click-overlay="false">
      <div class="end_page">
        <div style="margin-top: 20px;" @click="endRepeatPop=false">
          <van-icon name="arrow-left" />
          <span style="font-weight: bold;cursor: pointer;">结束重复</span>
        </div>
        <div class="show_data">
          <div class="select_repeat">
            <van-list>
              <van-radio-group v-model="endRepeat.endRepeatType">
                <van-cell>
                  <div style="display: flex;">
                    <van-radio :name="1"></van-radio>
                    <span style="margin-left: 5px;color: #595959;">一直重复</span>
                  </div>
                </van-cell>
                <van-cell>
                  <div class="end_flex">
                    <div style="display: flex;">
                      <van-radio :name="2"></van-radio>
                      <span style="margin-left: 5px;color: #595959;">重复次数</span>
                    </div>
                    <div v-if="endRepeat.endRepeatType==2">
                      <input type="number" class="time_input" v-model="endRepeat.repeatNum">
                      <span style="margin-left: 5px;color: #595959;">次</span>
                    </div>
                  </div>
                </van-cell>
                <van-cell>
                  <div style="display: flex;">
                    <van-radio :name="3"></van-radio>
                    <span style="margin-left: 5px;color: #595959;">结束重复时间</span>
                  </div>
                </van-cell>
              </van-radio-group>
            </van-list>
          </div>
          <div class="select_repeat" v-if="endRepeat.endRepeatType==3">
            <van-list>
              <van-cell>
                <div class="end_flex">
                  <div style="display: flex;">
                    <img src="../../../../assets/calendarImg1.png" alt="" class="tip-icon">结束日期</div>
                  <div style="width: 50%;display: flex;justify-content:flex-end;cursor: pointer;" @click="$refs.selectTime.setTipsDate()">
                    <div>{{ endRepeat.endDay }}</div>
                    <div><van-icon name="arrow" /></div>
                  </div>
                </div>
              </van-cell>
              <van-cell>
                <div class="end_flex">
                  <div style="display: flex;"><img src="../../../../assets/alarmImg1.png" alt="" class="tip-icon">结束时间</div>
                  <div style="width: 50%;display: flex;justify-content:flex-end;cursor: pointer;" @click="$refs.selectDate.show()">
                    <div>{{ endRepeat.endTime }}</div>
                    <div><van-icon name="arrow" /></div>
                  </div>
                </div>
              </van-cell>
            </van-list>
          </div>
        </div>
        <van-button type="primary" block style="margin-top: 25px;margin-bottom: 25px;" @click="defineBtn">确定</van-button>
      </div>
    </Popup>
    <!--    选择日期-->
    <selectTime ref="selectTime" @change="(e)=>endRepeat.endDay=e" />
    <!--      选择时间-->
    <selectDate ref="selectDate" @change="(e)=>endRepeat.endTime=e" />
  </div>
</template>
<script>
import { Popup, Divider, Toast } from 'vant'
import selectTime from '../contactTodo/selectTime'
import selectDate from '../contactTodo/selectDate'
export default {
  components: {
    Popup, Divider, selectTime, selectDate, Toast
  },
  data () {
    return {
      endRepeatPop: false,
      endRepeat: {
        endRepeatType: '',
        repeatNum: 1,
        endDay: '',
        endTime: ''
      }
    }
  },
  methods: {
    show () {
      this.endRepeatPop = true
    },
    defineBtn () {
      const endRepeatData = {
        endRepeatType: this.endRepeat.endRepeatType
      }
      if (this.endRepeat.endRepeatType == 2) {
        if (this.endRepeat.repeatNum < 1) {
          Toast('重复次数最小为1次')
          return false
        }
        endRepeatData.repeatNum = this.endRepeat.repeatNum
      }
      if (this.endRepeat.endRepeatType == 3) {
        if (this.endRepeat.endDay == '') {
          Toast('请选择结束日期')
          return false
        }
        endRepeatData.endDay = this.endRepeat.endDay
        if (this.endRepeat.endTime == '') {
          Toast('请选择结束时间')
          return false
        }
        endRepeatData.endTime = this.endRepeat.endTime
      }
      this.$emit('change', endRepeatData)
      this.endRepeatPop = false
    }
  }
}
</script>
<style scoped lang="less">
.end_flex{
  display: flex;
  justify-content:space-between;
}
.end_page{
  font-size: 26px;
  color: #333;
  padding: 0 35px;
}
.select_repeat{
  border: 1px solid #E8E8E8;
  padding: 0 10px;
  border-radius: 10px;
  margin-top: 30px;
}
.time_input{
  width: 100px;
  border: 1px solid #D9D9D9;
  margin-right: 5px;
  text-align: center;
}
.show_data{
  margin-top: 15px;
}
.tip-icon{
  width: 35px;
  height: 35px;
  margin-top: 3px;
  margin-right: 8px;
}
</style>
