import request from '@/utils/request'

/**
 * 客户轨迹
 */
export class ContactTrackApi {
  // 添加待办
  static index (params) {
    return request({
      url: '/contactTrack/index',
      method: 'get',
      params: params
    })
  }
}
