import request from '@/utils/request'

/**
 * 客户
 */
export class ContactEmployeeTagApi {
  // 个人标签添加
  static store (params) {
    return request({
      url: '/contactEmployeeTag/store',
      method: 'post',
      data: params
    })
  }

  // 客户画像-个人标签-列表
  static index (params) {
    return request({
      url: '/contactEmployeeTag/index',
      method: 'get',
      params
    })
  }

  // 客户画像-个人标签-删除
  static destroy (params) {
    return request({
      url: '/contactEmployeeTag/destroy',
      method: 'delete',
      data: params
    })
  }

  // 客户画像-个人标签-客户打标签
  static employeeTagStore (params) {
    return request({
      url: '/contactEmployeeTag/employeeTagStore',
      method: 'post',
      data: params
    })
  }
}
