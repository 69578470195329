<template>
  <div>
    <div class="drop-down" @click.stop="showOption">
      <span v-if="valueShow==''">请选择</span>
      <span v-else>{{ valueShow }}</span>
      <van-icon name="arrow-down" class="drop-icon" />
      <div class="drop-list" v-show="showList">
        <div class="drop-tip" v-if="optionList.length==0">暂无数据</div>
        <div v-show="optionList.length!=0">
          <div
            class="drop-option"
            v-for="(item,index) in optionList"
            :key="index"
            @click.stop="selectOption(item)"
          >{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    optionData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      showList: false,
      valueShow: '',
      optionList: this.optionData,
      optionId: ''
    }
  },
  watch: {
    // 正确给 cData 赋值的 方法
    optionData (value) {
      this.optionList = value
    }
  },
  methods: {
    // 显示选项
    showOption () {
      this.showList = true
    },
    // 不设置默认选项
    emptyOption () {
      this.valueShow = ''
      this.optionId = ''
    },
    // 设置默认选项
    setOption (id) {
      this.optionId = id
      this.optionList.forEach((item, index) => {
        if (item.id == this.optionId) {
          this.valueShow = item.name
        }
      })
    },
    //  关闭选项
    closeOption () {
      this.showList = false
    },
    //  选择选项
    selectOption (item) {
      this.showList = false
      this.valueShow = item.name
      this.$emit('selectValue', item)
      this.$emit('change', item.id)
    }
  }
}
</script>
<style scoped lang="less">
.drop-down{
  font-size: 14px !important;
  height: 30px;
  display: flex;
  align-items: center;
  width: 280px;
  height: 37px;
  line-height: 37px;
  padding-left: 10px;
  background: #fff;
  color: #8D8D8D;
  position: relative;
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 5px;
}
.drop-icon{
  position: absolute;
  top: 10px;
  right: 10px;
  color: #8D8D8D;
}
.drop-list{
  width: 290px;
  margin: 0;
  padding: 0;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #fff;
  position: absolute;
  z-index: 10000;
  top: 37px;
  left: 0;
  border-radius: 5px;
  box-shadow: 1px 5px 10px #888888;
}
.drop-option{
  width: 100%;
  height: 57px;
  line-height: 57px;
  margin-left: 15px;
}
.drop-option:hover{
  background: #E6F7FF;
}
.drop-tip{
  width: calc(100% - 30px);
  height: 57px;
  line-height: 57px;
  padding-left:15px;
}
</style>
