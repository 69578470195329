<template>
  <div>
    <Popup v-model:show="timePopup" position="bottom" :style="{ height: '50%' }">
      <DatetimePicker
        v-model="currentDate"
        :type="dateType"
        title="选择完整时间"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="cancelTimePopup"
        @confirm="selectTime"
      />
    </Popup>
    <DatetimePicker
      v-model="currentDate"
      :type="dateType"
      title="选择完整时间"
      :min-date="minDate"
      :max-date="maxDate"
      v-show="abc"
    />
  </div>
</template>
<script>
import { Popup, DatetimePicker } from 'vant'
export default {
  components: {
    Popup, DatetimePicker
  },
  data () {
    return {
      timePopup: false,
      currentDate: '',
      currentDate1: '',
      minDate: new Date(),
      maxDate: new Date(),
      abc: false,
      dateType: ''
    }
  },
  methods: {
    show (type) {
      // minDate: new Date(1949, 0, 1),
      // maxDate: new Date(2050, 10, 1),
      if (type == 1) {
        this.dateType = 'date'
      } else {
        this.dateType = 'datetime'
      }
      this.timePopup = true
    },
    // 设置提醒日期
    setTipsDate () {
      this.dateType = 'date'
      this.maxDate = new Date(2050, 12, 31)
      this.timePopup = true
    },
    // 设置提醒时间
    putTipsTime () {
      this.dateType = 'time'
      this.timePopup = true
    },
    selectTime () {
      let year = ''
      let month = ''
      let date = ''
      let hours = ''
      let minutes = ''
      let optTime = ''
      if (this.dateType != 'time') {
        year = this.currentDate.getFullYear()
        month = this.currentDate.getMonth() + 1 > 9 ? (this.currentDate.getMonth() + 1) : '0' + (this.currentDate.getMonth() + 1)
        date = this.currentDate.getDate() > 9 ? this.currentDate.getDate() : '0' + this.currentDate.getDate()
      }
      if (this.dateType == 'datetime') {
        hours = this.currentDate.getHours() > 9 ? this.currentDate.getHours() : '0' + this.currentDate.getHours()
        minutes = this.currentDate.getMinutes() > 9 ? this.currentDate.getMinutes() : '0' + this.currentDate.getMinutes()
      }

      if (this.dateType == 'time') {
        this.$emit('change', this.currentDate)
      } else {
        if (this.dateType == 'datetime') {
          optTime = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes
          // optTime = {
          //   year, month, date, hours, minutes
          // }
        } else if (this.dateType == 'date') {
          optTime = year + '-' + month + '-' + date
          // optTime = {
          //   year, month, date
          // }
        }
        this.$emit('change', optTime)
      }
      this.timePopup = false
    },
    cancelTimePopup () {
      this.timePopup = false
    }
  }
}
</script>
