<template>
  <div class="index">
    <van-nav-bar
        title="筛选客户"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
<!--        @click-left="onClickLeft"-->
<!--        @click-right="onClickRight"-->
    <div class="content">
      <div class="item">
        <div class="list-item">
          <div class="item-title">
            所属部门
          </div>
          <!-- <div @click="$refs.department.$show(departmentList,'max')" class="item-content">
            {{ departmentList.length > 0 ? departmentList[0].name : '请选择部门' }}
            <van-icon name="arrow-down"/>
          </div> -->
          <div @click="$refs.department.$show(departmentList)" style="margin-top: 10px;">
            <van-button style="height: 30px;" plain type="info">选择部门</van-button>
          </div>
          <div v-if="departmentList.length > 0" style="margin-top: 10px;">
            <van-tag style="margin-right: 5px" v-for="(item,index) in departmentList" :key="index" closeable plain  size="medium" type="primary" @close="departmentList.splice(index,1)">{{ item.title }}</van-tag>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            所属客服
          </div>
          <div @click="$refs.employee.$show(StandbyStaffList,'max')" class="item-content">
            {{ StandbyStaffList.length > 0 ? String(StandbyStaff) : '请选择客服' }}
            <van-icon name="arrow-down"/>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            客户等级
          </div>
          <div @click="$refs.stage.$show(stage,'max')" class="item-content">
            {{ stage.length > 0 ? stage[0].name : '请选择等级' }}
            <van-icon name="arrow-down"/>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            系统标签
          </div>
          <div @click="$refs.systemTags.$show(systemTags)" style="margin-top: 10px;">
            <van-button style="height: 30px;" plain type="info">选择标签</van-button>
          </div>
          <div v-if="systemTags.length > 0" style="margin-top: 10px;">
            <van-tag style="margin-right: 5px" v-for="(item,index) in systemTags" :key="index" closeable plain  size="medium" type="primary" @close="systemTags.splice(index,1)">{{ item.name }}</van-tag>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            企业标签
          </div>
          <div @click="$refs.tags.$show(tagsList)" style="margin-top: 10px;">
            <van-button style="height: 30px;" plain type="info">选择标签</van-button>
          </div>
          <div v-if="tagsList.length > 0" style="margin-top: 10px;">
            <van-tag style="margin-right: 5px" v-for="(item,index) in tagsList" :key="index" closeable plain  size="medium" type="primary" @close="tagsList.splice(index,1)">{{ item.name }}</van-tag>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            添加时间
          </div>
          <div class="item-content" @click="$refs.time.$show()">
            <div @click="$refs.time.$show()" style="flex: 1;text-align: center">{{ sTime ? sTime : '开始时间' }}</div>
            -
            <div @click="$refs.time.$show()" style="flex: 1;text-align: center">{{ endTime ? endTime : '结束时间' }}</div>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            添加渠道
          </div>
          <div>
            <div v-for="(item,index) in qcode" :key="index" @click="activeq(index)" :class="{qd: item.chose}" class="item-content item-lable">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            客户性别
          </div>
          <div>
            <div @click="codew = 0" :class="{qd: codew == 0}"  class="item-content item-lable" style="    padding: 0 26px;">
              未知
            </div>
            <div @click="codew = 1" :class="{qd: codew == 1}" class="item-content item-lable" style="    padding: 0 26px;">
              男
            </div>
            <div @click="codew = 2" :class="{qd: codew == 2}"  class="item-content item-lable" style="    padding: 0 26px;">
              女
            </div>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            流失状态
          </div>
          <div>
            <div @click="lose = 1" :class="{qd: lose == 1}" class="item-content item-lable">
              已流失
            </div>
            <div @click="lose = 2" :class="{qd: lose == 2}" class="item-content item-lable">
              未流失
            </div>
          </div>
        </div>
        <div style="    justify-content: space-around;
    width: 100%;display: flex;    justify-content: space-between;">
          <van-button @click="onClickLeft" style="width: 35%;" block type="default">取消</van-button>
          <van-button @click="customer" style="width: 35%;" block type="info">确定</van-button>
        </div>
      </div>

    </div>
    <!--  选择客户  -->
    <addCustomer ref="customer"/>
    <!--  选择标签  -->
    <addTags ref="tags" @change="tagsChose"/>
    <!--  时间选择  -->
    <addTime ref="time" @change="choseTime"/>

    <employeeIndex ref="employee" @change="userChange"/>

    <departmentSelect ref="department" @change="departmentChange"/>

    <stageSelect ref="stage" @change="stageChange"></stageSelect>

    <addTags ref="systemTags" :is-system="1" @change="systemTagsChose"/>
  </div>
</template>

<script>
import addCustomer from "../index/components/addCustomer";
import addTags from "../channelCode/components/customerLabel";
import employeeIndex from "../channelCode/components/employeeIndex";
import departmentSelect from "@/components/departmentSelect/index";
import addTime from "../channelCode/components/addTime";

import stageSelect from "../../components/stageSelect/";

export default {
  components: {
    addCustomer,
    addTags,
    addTime,
    employeeIndex,
    departmentSelect,
    stageSelect
  },
  data() {
    return {
      // 文字
      message: '',
      // tbas切换
      tabsChose: 0,
      // 员工
      StandbyStaffList: [],
      StandbyStaff:[],
      stage: [],
      departmentList: [],
      tagsList: [],
      systemTags: [],
      sTime: '',
      endTime: '',

      //渠道
      qcode: [{chose: true,addWay:2,name: '搜索手机号'},{chose: false,addWay:5,name: '手机通讯录'},{chose: false,addWay:8,name: '安装第三方应用时添加的客服人员'},{chose: false,addWay:202,name: '管理员/负责人分配'},{chose: false,addWay:1003,name: '裂变引流'}],
      // 性别
      codew: 0,
      lose: 1
    }
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    // 员工
    userChange(data, info) {
      this.StandbyStaffList = data
      this.StandbyStaff = data.map(i=>i.name)
    },
    tagsChose(data){
      this.tagsList = data
      console.log(data)
    },
    systemTagsChose (data) {
      this.systemTags = data
      console.log(data)
    },
    departmentChange (data) {
      this.departmentList = data
      console.log(data)
    },
    stageChange (data) {
      this.stage = data
      console.log(data)
    },
    choseTime(data){
      this.sTime = data.time.split("-")[0]
      this.endTime = data.time.split("-")[1]
    },
    activeq(i){
      this.qcode.forEach(i=>{
        return i.chose = false
      })
      this.qcode[i].chose = true
    },
    customer(){
      const content = {
        // employeeId: this.StandbyStaffList[0].id,
        gender: this.codew,
        status: this.lose
      }
      if (this.StandbyStaffList[0]) {
        content.employeeId = this.StandbyStaffList[0].id
      }
      if (this.departmentList.length > 0) {
        content.departmentId = []
        for(var i = 0; i < this.departmentList.length; i++){
          content.departmentId.push(this.departmentList[i].id)
        }
      }
      if (this.tagsList.length > 0) {
        content.tags = []
        for(var i = 0; i < this.tagsList.length; i++){
          content.tags.push(this.tagsList[i].id)
        }
      }
      if (this.systemTags.length > 0) {
        content.systemTags = []
        for(var i = 0; i < this.systemTags.length; i++){
          content.systemTags.push(this.systemTags[i].id)
        }
      }
      if (this.stage && this.stage[0]) {
        content.stage = this.stage[0].id
      }
      console.log(content)
      this.$router.push({
        path: "/contact/index",
        query:{
          content: JSON.stringify(content)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
/deep/.van-nav-bar__content{
  background: #2b63cd;
  color: #fff;
}
/deep/.van-nav-bar .van-icon{
  color: #fff;
}
/deep/.van-nav-bar__text{
  color: #fff;
}
/deep/.van-nav-bar__title{
  color: #fff;
}
.index {
  font-size: 14px;
  height: 100%;
  background: #F1f2f5;

  p {
    margin: 0 !important;
  }
  .qd{
    color: #1890ff!important;
    border-color: #1890ff!important;
    background-color: #f3f9ff!important;
  }
  .content {
    height: 92%;

    img {
      width: 22px;
      height: 22px;
    }

    padding: 12px 16px;

    .item {
      background: #fff;
      padding: 20px 15px;
      height: 100%;
      overflow: auto;
      padding-bottom: 20px;
      .list-item {
        margin-bottom: 20px;
      }

      .item-title {
        font-weight: bold;
      }

      .item-content {
        width: 100%;
        /* background: red; */
        border: 1px solid #E5E6E7;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        border-radius: 7px;
        margin-top: 10px;
      }

      .item-lable {
        display: inline-block;
        width: auto;
        align-items: center;
        line-height: 36px;
        margin: 10px 3px;
        border-radius: 0;
      }
    }
  }

  .bottom-button {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 20px;
    justify-content: space-around;
    width: 100%;
  }
}
</style>
