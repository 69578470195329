<template>
  <div>
    <Popup
      v-model:show="createPop"
      position="bottom"
      closeable
      round
      :close-on-click-overlay="false"
      @click-close-icon="cancelBtn"
    >
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">模板拉群</div>
      <Divider />
      <div class="create_page">
        <div style="display: flex;">
          <div class="title-left"></div>
          <span>设置拉群模板，完成后按照此模板创建的群聊将会自动选择员工，并一键设置群信息和规则</span>
        </div>
        <div style="margin-top: 15px;">
          <div style="color: #878787;"><span style="color: red;margin-right:3px;font-size: 14px;font-weight: bold;">*</span>拉群模板名称</div>
          <input type="text" placeholder="请设置拉群模板名称" class="formwork_name" v-model="templateName">
          <div style="color: #878787;margin-top: 10px;"><span style="color: red;margin-right:3px;font-size: 14px;font-weight: bold;">*</span>选择模板内员工</div>
          <div class="select_staff" @click="$refs.selectMember.show(employeeArr)">
            <span class="tip" v-if="employeeArr.length==0">请选择员工</span>
            <div v-else>
              <van-tag type="primary" v-for="(item,index) in employeeArr" :key="index" style="margin-left: 5px;">{{ item.name }}</van-tag>
            </div>
          </div>
          <div style="color: #999;font-size: 13px;margin-top: 10px;">当前可选择{{ employeeNum }}位员工</div>
        </div>
        <div class="new_template_foot">
          <van-button type="default" @click="cancelBtn">取消</van-button>
          <van-button type="primary" @click="determineBtn">
            <span v-if="popupType==1">创建模板</span>
            <span v-if="popupType==2">修改模板</span>
          </van-button>
        </div>
      </div>
    </Popup>
    <selectMember ref="selectMember" @change="receiveData" />
  </div>
</template>
<script>
import { Popup, Divider, NoticeBar, Toast } from 'vant'
// eslint-disable-next-line no-unused-vars
import { RoomTemplatePullApi } from '@/api/roomTemplatePull'
// eslint-disable-next-line no-unused-vars
import { EmployeeApi } from '@/api/employee'
import selectMember from './selectMember'
export default {
  components: {
    Popup, Divider, NoticeBar, selectMember, Toast
  },
  data () {
    return {
      createPop: false,
      contactId: '',
      templateId: '',
      // 模板名称
      templateName: '',
      employeeArr: [],
      popupType: '',
      employeeNum: ''
    }
  },
  methods: {
    // 修改
    modifyShow (contactId, templateId) {
      this.popupType = 2
      this.contactId = contactId
      this.templateId = templateId
      this.createPop = true
      RoomTemplatePullApi.show({
        id: this.templateId
      }).then((res) => {
        this.templateName = res.data.name
        this.employeeArr = res.data.employees
      })
    },
    // 接收组件数据
    receiveData (e) {
      this.employeeArr = []
      if (e.length) {
        e.forEach((item, index) => {
          this.employeeArr[index] = JSON.parse(item)
        })
      }
    },
    // 创建模板
    determineBtn () {
      // Toast
      if (this.templateName == '') {
        Toast('模板名字不能为空')
        return false
      }
      if (this.employeeArr.length == 0) {
        Toast('请选择员工')
        return false
      }
      const employeeIds = this.employeeArr.map((value, index, arr) => {
        return value.id
      })
      if (this.popupType == 1) {
        RoomTemplatePullApi.store({
          name: this.templateName,
          contactId: this.contactId,
          employeeIds
        }).then((res) => {
          Toast('创建成功')
          this.cancelBtn()
          this.$emit('change', true)
        })
      } else if (this.popupType == 2) {
        RoomTemplatePullApi.update({
          contactId: this.contactId,
          id: this.templateId,
          name: this.templateName,
          employeeIds
        }).then((res) => {
          Toast('修改成功')
          this.cancelBtn()
          this.$emit('change', true)
        })
      }
    },
    // 取消按钮
    cancelBtn () {
      this.templateName = ''
      this.employeeArr = []
      this.createPop = false
    },
    getMemberNum () {
      // EmployeeApi
      EmployeeApi.index({
        name: this.employeeName
      }).then((res) => {
        this.employeeNum = res.data.length
      })
    },
    show (contactId) {
      this.createPop = true
      this.contactId = contactId
      this.popupType = 1
      //  获取成员数量 EmployeeApi
      this.getMemberNum()
    }
  }
}
</script>
<style scoped lang="less">
.create_flex{
  display: flex;
  justify-content:space-between;
}
.create_page{
  padding: 0 35px 35px 35px;
  font-size: 27px;
  color: #333;
}
.formwork_name{
  width: 100%;
  border: 1px solid #D9D9D9;
  height: 50px;
  padding-left: 10px;
  margin-top: 20px;
}
.select_staff{
  width: 100%;
  height: 60px;
  border: 1px solid #D9D9D9;
  line-height: 60px;
  padding-left: 10px;
  margin-top: 20px;
  .tip{
    color: #999;
  }
}
.new_template_foot{
  margin-top: 50px;
  display: flex;
  justify-content:space-between;
  button{
    width: calc(50% - 10px);
  }
}
.title-left{
  width: 2px;
  height: 28px;
  margin-right: 7px;
  background: #1890FF;
  border: 3px solid #1890FF;
}
</style>
