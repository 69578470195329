<template>
  <div>
    <Popup
      v-model:show="friendRoomPop"
      position="bottom"
      closeable
      :close-on-click-overlay="false"
      :style="{ height: '600px' }">
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">好友和群聊</div>
      <Divider />
      <div class="friend_page">
        <div>Ta添加的员工({{ roomData.employeeName.length }})</div>
        <div v-for="(item,index) in roomData.employeeName" :key="index" style="margin-top: 15px;">
          <div style="display: flex;cursor: pointer;" @click="$refs.employeePopup.show(item)">
            <img :src="item.avatar" alt="" style="width: 50px;height: 50px;">
            <div style="margin-left: 10px;">
              <div style="color: #333;">{{ item.employeeName }}</div>
              <div style="color: #D0D0D0;font-size: 14px;margin-top: 8px;">添加时间：<span style="color: #595959;">{{ item.createTime }}</span></div>
            </div>
          </div>
          <Divider dashed />
        </div>
        <div>Ta加入的群聊({{ roomData.roomName.length }})</div>
        <div
          class="friend_flex"
          style="margin-top: 15px;padding: 15px;background: #F9F9F9;"
          v-for="(item,index) in roomData.roomName"
          :key="index"
        >
          <div style="display: flex;">
            <img src="../../../assets/roomSvgImg.svg" alt="" style="width: 50px;height: 50px;">
            <div style="margin-left: 10px;color: #D0D0D0;font-size: 14px;">
              <div><span style="color: #4E4E4E;">{{ item.name }}</span></div>
              <div>群主：{{ item.ownerName }}</div>
              <div>入群时间：<span style="color: #4E4E4E;">{{ item.joinTime }}</span></div>
              <van-tag plain type="primary" size="medium" style="margin-top: 5px;" v-if="item.isJoin==1">我已在群聊</van-tag>
              <van-tag plain type="danger" size="medium" style="margin-top: 5px;" v-if="item.isJoin==0">我不在群聊</van-tag>
            </div>
          </div>
          <img src="../../../assets/copyImg.png" alt="" style="width: 20px;height: 20px;margin-top: 20px;margin-right: 10px;cursor: pointer;" @click="copyBtn(item)">
        </div>
        <div style="margin-top: 17px;text-align: center;color: #8C8C8C;font-size: 14px;">没有更多了</div>
      </div>
    </Popup>
    <input type="text" class="copy-input" ref="copyInput">
    <employeePopup ref="employeePopup" />
  </div>
</template>
<script>
import employeePopup from '../components/employeePopup'
// eslint-disable-next-line no-unused-vars
import { setClipboardData } from '@/utils/wxCodeAuth'
import { Popup, Divider, Toast } from 'vant'
export default {
  components: {
    Popup, Divider, employeePopup
  },
  data () {
    return {
      friendRoomPop: false,
      roomData: {
        roomName:[],
        employeeName: []
      }
    }
  },
  methods: {
    async copyBtn (item) {
      Toast('复制成功')
      await setClipboardData(item.name)
    },
    show (roomData) {
      this.friendRoomPop = true
      this.roomData = roomData
    }
  }
}
</script>
<style scoped lang="less">
.friend_flex{
  display: flex;
  justify-content:space-between;
}
.friend_page{
  padding: 0 35px 30px 35px;
  font-size: 14px;
  color: #6D6D6D;
}
.copy-input{
  width: 10px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
}
</style>
