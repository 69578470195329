<template>
  <div class="component">
    <van-action-sheet v-model="show" title="选择部门">
      <div class="content" style="color: #818181;">
        <div class="user-list">
          <!-- <van-checkbox-group @change="fullRadio" v-if="user.length > 0" icon-size="15px" v-model="radio">
            <div>
              <div style="padding-left: 10px" v-for="(items,indexs) in user" :key="indexs">
                <div class="group_name" style="font-weight: bolder;font-size: 15px">{{ items.groupName }}</div>

                <div v-for="(item,index) in items.tags" :key="index" :class="{'active-background':  radio == item.id }"
                     class="user" style="padding: 10px 16px;">
                  <div>
                    {{ item.name }}
                  </div>
                  <van-checkbox ref="choseRadio" shape="square" :name="item"></van-checkbox>

                </div>
              </div>
            </div>
          </van-checkbox-group> -->
          <a-tree
            v-if="list.length && list[0]"
            v-model="selected"
            checkable
            :tree-data="list"
            :replace-fields="{children:'childDepartment', title:'name', key:'id' }"
            defaultExpandAll
            checkStrictly
            @check="checkNode"
          >
          </a-tree>
          <van-empty
            v-else
            class="custom-image"
            :image="require('@/assets/images/mobiletodo.png')"
            description="分组"
          />
        </div>
        <van-button @click="determine" block style="width: 90%;margin: 0 auto;margin-top: 10px;margin-bottom: 10px"
                    type="info">确定
        </van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>

import { departmentIndex } from '@/api/common'

export default {
  data() {
    return {
      show: false,
      radio: [],
      list: [],
      selected: [],
    }
  },
  watch: {},
  methods: {
    $show(data) {
      this.show = true
      departmentIndex().then(res => {
        if (res.data) {
          this.list = [res.data]
        }
        if (data) {
          let that = this
          let selectArr = []
          for (var i = 0; i < data.length; i++) {
            selectArr.push(data[i].id)
          }
          const selectNode = function (arr) {
            for (var i = 0; i < arr.length; i++) {
              if (selectArr.indexOf(arr[i].id) != -1 ) {
                that.radio.push({
                  title: arr[i].name,
                  id: arr[i].id
                })
              }
              if (arr[i].childDepartment && arr[i].childDepartment.length > 0) {
                selectNode(arr[i].childDepartment)
              }
            }
          }

          selectNode(this.list)
          this.selected = selectArr
          console.log(this.radio)
        }
      })
      // if (data.length <= 0 || this.user.length <= 0 && data.length > 0) {
      //   departmentIndex().then(res => {
      //     res.data.forEach(item => {
      //       item.show = false
      //     })
      //     const that = this
      //     this.user = res.data.map(item => {
      //       return {
      //         groupName: item.groupName,
      //         id: item.id,
      //         show: item.show,
      //         wxGroupId: item.wxGroupId,
      //         tags: item.tags.map(it => {
      //           return {
      //             id: it.id,
      //             name: it.name
      //           }
      //         })
      //       }
      //     })
      //     if(data.length > 0){
      //       this.editCode(data)
      //     }
      //   })
      // }else{
      //   this.editCode(data)
      // }
    },
    checkNode (checkedKeys, e) {
      console.log(e)
   
      if (e.checked) {
        this.radio.push({
          title: e.node.title,
          id: e.node.eventKey
        }) 
      } else {
        for (var i = 0; i < this.radio.length; i++) {
          if (this.radio[i].id == e.node.eventKey) {
            this.radio.splice(i, 1)
            break
          }
        }
      }
      console.log(this.radio)
      console.log(this.selected)
    },
    determine(e) {
      if (!this.radio) {
        Toast.fail('请选择部门');
      }
      const data = this.radio
      this.$emit("change", data)
      this.show = false
      this.radio = []
    },
    fullRadio() {
    },
    editCode(data) {
      this.$nextTick(() => {
        data.forEach((item,index)=>{
          this.user.forEach((is,ins)=>{
            is.tags.forEach((its,ind)=>{
              if(item.id == its.id){
                this.$refs.choseRadio[ind].toggle(true)
              }
            })
          })
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}

/deep/ .van-action-sheet__header {
  font-weight: bolder;
}

.component {
  .content {

    .user-list {
      height: 275px;
      overflow: auto;
      padding: 20px;

      .group_name{
        color: #747474;
        margin: 25px 0 15px 0;
        border-left: 8px solid #1989FA;
        padding-left: 8px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
      }
      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-size: 14px;
          color: #252528;

          img {
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }

      .active-background {
        background: #f6fbff !important;
      }

    }
  }
}
</style>
