<template>
  <div>
    <Popup
      v-model:show="showDetailOption"
      position="bottom"
      closeable
      :close-on-click-overlay="false"
      :style="{ height: '350px' }">
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">选择状态</div>
      <Divider />
      <div class="show-option">
        <van-list>
          <van-cell style="padding: 0;" v-for="(item,index) in optionList" :key="index" @click="selectRow(item.value)">
            <div class="option_list" :class="[selectKey==item.value?'select_row':'']">{{ item.value }}</div>
          </van-cell>
        </van-list>
      </div>
      <div style="display: flex;justify-content:center;margin-top: 15px;">
        <van-button type="info" block style="width: calc(100% - 30px);" @click="keepSetup">保存</van-button>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Popup, Divider, Toast } from 'vant'
export default {
  components: {
    Popup, Divider, Toast
  },
  data () {
    return {
      showDetailOption: false,
      optionList: [],
      selectKey: null
    }
  },
  methods: {
    show (data) {
      this.showDetailOption = true
      this.optionList = data.selectOptions
      if (data.value) {
        this.selectKey = JSON.parse(JSON.stringify(data.value))
      } else {
        this.selectKey = undefined
      }
    },
    // 保存设置
    keepSetup () {
      if (!this.selectKey) {
        Toast('请选择设置选项')
        return false
      }
      this.$emit('change', this.selectKey)
      this.showDetailOption = false
    },
    // 选择跟进状态
    selectRow (name) {
      this.selectKey = name
    }
  }
}
</script>
<style scoped lang="less">
.option_list{
  text-align: center;
  padding: 15px 0;
  color: #595959;
  cursor: pointer;
}
.option_list:hover{
  background: #EFFAFF;
}
.select_row{
  color: #319CFF;
  background: #EFFAFF;
}
.show-option{
  margin-top: -20px;
  padding: 0 15px;
  height: 225px;
  overflow-y: scroll;
}
</style>
